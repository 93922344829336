import React, { CSSProperties } from 'react';
import { SelectUnderlyingMarketCallback, UnderlyingMarket } from './MtfScreener';
import { AutoSizer, List } from 'react-virtualized';
import UnderlyingMarketRow from './UnderlyingMarketRow';
import { useWebsiteScreenerParams } from '../hooks/use-website-screener-params';

export type UnderlyingMarketListProps = {
  data: UnderlyingMarket[];
  scrollToIndex?: number;
  selectUnderlyingMarket: SelectUnderlyingMarketCallback;
  selectedUnderlyingMarketId: string;
  priceTicksEnabled: boolean;
};

const UnderlyingMarketList: React.FC<UnderlyingMarketListProps> = ({
  data,
  scrollToIndex,
  selectUnderlyingMarket,
  selectedUnderlyingMarketId,
  priceTicksEnabled,
}) => {
  const { rowHeight } = useWebsiteScreenerParams();

  function rowRenderer({
    key,
    style,
    index,
  }: {
    key: string;
    style: CSSProperties;
    index: number;
  }) {
    const market: UnderlyingMarket = data[index];
    return (
      market && (
        <UnderlyingMarketRow
          key={key}
          style={style}
          market={market}
          selectUnderlyingMarket={selectUnderlyingMarket}
          selectedUnderlyingMarketId={selectedUnderlyingMarketId}
          priceTicksEnabled={priceTicksEnabled}
        />
      )
    );
  }

  return (
    <AutoSizer>
      {({ height, width }) => (
        <List
          width={width}
          height={height}
          rowCount={data.length}
          rowHeight={rowHeight}
          rowRenderer={rowRenderer}
          scrollToIndex={scrollToIndex}
        />
      )}
    </AutoSizer>
  );
};

export default UnderlyingMarketList;

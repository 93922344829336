import { useEffect, useReducer, useRef } from 'react';
import abortableFetch from '../utils/fetch';
import { useSessionData } from './use-session-data';
import constantReducer, { generateUrl } from './constant-reducer';
import {
  DEFAULT_FILTER_STATE,
  ConstantFiltersState,
  ConstantSortingState,
} from '../components/ConstantFilters';
import useSessionStorage from './use-session-storage';
import { useActions } from './use-actions';
import { ConstantResponse } from '../components/ConstantTab';
import { useWebsiteScreenerParams } from './use-website-screener-params';

export const CONSTANT_SORT_SESSION_STORAGE_KEY = 'constant-sort';
export const FILTER_STATE_SESSION_STORAGE_KEY = 'constant-search-filters';

export enum ConstantSortableFields {
  underlyingName,
  optionType,
  leverageFactor,
}

export const DEFAULT_SORT = {
  sortBy: ConstantSortableFields.leverageFactor,
  sortAsc: false,
};

export type ConstantURLParams = {
  instrumentType: string;
  fields: string;
  underlyingId?: string[];
  optionType?: 'CALL' | 'PUT';
  pageSize: number;
  pageNumber: number;
  sort?: string;
  leverageFactor?: string[];
  locale?: string;
};

const useConstant = () => {
  const { allowUnauthorizedAPI, locale } = useWebsiteScreenerParams();
  const { apiHost, cst, currentAccountId, xst } = useSessionData();
  const { actions } = useActions();

  const [constantFilters, saveConstantFilters] = useSessionStorage(
    FILTER_STATE_SESSION_STORAGE_KEY,
    DEFAULT_FILTER_STATE
  );
  const [sortPreference, saveSortingPreference] = useSessionStorage(
    CONSTANT_SORT_SESSION_STORAGE_KEY,
    DEFAULT_SORT
  );
  const [state, dispatch] = useReducer(constantReducer, {
    apiHost,
    data: [],
    error: null,
    filters: constantFilters,
    isLoading: true,
    pageNumber: 0,
    sorting: sortPreference,
    totalPages: 0,
    totalResults: 0,
    allowUnauthorizedAPI,
    locale,
    url: generateUrl(allowUnauthorizedAPI, locale, apiHost, 0, constantFilters, sortPreference),
  });

  const abortables = useRef<Function[]>([]);

  useEffect(() => {
    const [fetch, abort] = allowUnauthorizedAPI
      ? abortableFetch(null, null)
      : abortableFetch(xst, cst, currentAccountId);

    /* istanbul ignore if */
    if (!state.url) {
      return () => abort();
    }
    dispatch({ type: 'LOADING_STARTED' });
    (async () => {
      try {
        abortables.current = [...abortables.current, abort];
        const response = await fetch(state.url, { method: 'get' });
        abortables.current = abortables.current.filter((a) => a !== abort);
        if (!response.ok) {
          dispatch({ type: 'ERROR', error: response.statusText });
          return;
        }
        const json: ConstantResponse = await response.json();

        dispatch({
          type: 'LOADING_COMPLETE',
          instruments: json.results.instruments,
          pageNumber: json.meta.pageNumber,
          totalPages: json.meta.totalPages,
          totalResults: json.meta.totalResults,
        });
      } catch (error) /* istanbul ignore next */ {
        if (error.name !== 'AbortError') {
          dispatch({ type: 'ERROR', error: error.toString() });
        }
      }
    })();
  }, [apiHost, cst, currentAccountId, state.url, xst, allowUnauthorizedAPI]);

  useEffect(() => {
    return () => {
      abortables.current.map((abort) => abort());
    };
  }, []);

  return {
    loadNextPage: () => {
      dispatch({ type: 'LOAD_NEXT_PAGE' });
    },
    trackFilterInteraction: (
      interactionType: string,
      interactionValue: string | undefined,
      instrumentName: string | undefined
    ) => {
      /* istanbul ignore else */
      if (actions.onSearchInteraction) {
        actions.onSearchInteraction(interactionType, interactionValue, instrumentName, 'Constants');
      }
    },
    updateFilters: (filters: ConstantFiltersState) => {
      saveConstantFilters(filters);
      dispatch({ type: 'SET_FILTERS', filters });

      /* istanbul ignore else */
      if (actions.onFilter) {
        actions.onFilter('Constants');
      }
    },
    updateSorting: (sorting: ConstantSortingState) => {
      saveSortingPreference(sorting);
      dispatch({ type: 'SET_SORTING', sorting });

      /* istanbul ignore else */
      if (actions.onSort) {
        actions.onSort(
          `${ConstantSortableFields[sorting.sortBy]}:${sorting.sortAsc ? 'asc' : 'desc'}`,
          'Constants'
        );
      }
    },
    ...state,
  };
};
export default useConstant;

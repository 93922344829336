import React, { createContext, useContext } from 'react';

export const THEME_CLASSES = {
  DEFAULT: 'light',
  LIGHT: 'light',
  DARK: 'dark',
  WEBSITE: 'website',
};

type IWebsiteScreenerParamsContext = {
  currentTheme: string;
  isWebsiteTheme: boolean;
  rowHeight: number;
  locale: string;
  allowUnauthorizedAPI: boolean;
};

type WebsiteScreenerParamsProps = {
  children: React.ReactNode;
  theme: string;
  locale: string;
  allowUnauthorizedAPI: boolean;
};

export const WebsiteScreenerParamsContext = createContext<IWebsiteScreenerParamsContext>({
  currentTheme: THEME_CLASSES.DEFAULT,
  isWebsiteTheme: false,
  rowHeight: 25,
  locale: 'en_GB',
  allowUnauthorizedAPI: false,
});

export const WebsiteScreenerParamsProvider: React.FC<WebsiteScreenerParamsProps> = ({
  children,
  theme,
  locale,
  allowUnauthorizedAPI,
}) => (
  <WebsiteScreenerParamsContext.Provider
    value={{
      currentTheme: theme,
      isWebsiteTheme: theme === THEME_CLASSES.WEBSITE,
      rowHeight: theme === THEME_CLASSES.WEBSITE ? 57 : 25,
      locale,
      allowUnauthorizedAPI,
    }}
  >
    {children}
  </WebsiteScreenerParamsContext.Provider>
);

export const useWebsiteScreenerParams = () => useContext(WebsiteScreenerParamsContext);

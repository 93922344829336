import React from 'react';
// @ts-ignore
import gridStyles from '../styles/grid.module.css';
import { Constant, InstrumentType, SelectMarketCallback, Turbo, Warrant } from './MtfScreener';
import IsinListDefaultHeader from './IsinListDefaultHeader';
import TurboRow from './TurboRow';
import IsinListConstantHeader from './IsinListConstantHeader';
import ConstantRow from './ConstantRow';
import IsinListWarrantHeader from './IsinListWarrantHeader';
import WarrantRow from './WarrantRow';

export type IsinListProps = {
  instrument: Constant | Turbo | Warrant | null;
  isLoading: boolean;
  priceTicksEnabled: boolean;
  selectMarket: SelectMarketCallback;
  selectedMarketId: string;
  utcOffset: number;
};

const IsinList: React.FC<IsinListProps> = ({
  instrument,
  isLoading,
  priceTicksEnabled,
  selectMarket,
  selectedMarketId,
  utcOffset,
}) => {
  const getListComponents = (instrument: Constant | Turbo | Warrant | null) => {
    if (!instrument) {
      return {
        listHeader: <IsinListDefaultHeader priceTicksEnabled={priceTicksEnabled} />,
        listRow: null,
      };
    }

    if (instrument.instrumentType === InstrumentType.CONSTANT) {
      return {
        listHeader: <IsinListConstantHeader priceTicksEnabled={priceTicksEnabled} />,
        listRow: (
          <ConstantRow
            key={'key'}
            style={{}}
            constant={instrument as Constant}
            selectMarket={selectMarket}
            selectedMarketId={selectedMarketId}
            priceTicksEnabled={priceTicksEnabled}
          />
        ),
      };
    }

    if (instrument.instrumentType === InstrumentType.WARRANT) {
      return {
        listHeader: <IsinListWarrantHeader priceTicksEnabled={priceTicksEnabled} />,
        listRow: (
          <WarrantRow
            key={'key'}
            style={{}}
            warrant={instrument as Warrant}
            selectMarket={selectMarket}
            selectedMarketId={selectedMarketId}
            utcOffset={utcOffset}
            priceTicksEnabled={priceTicksEnabled}
          />
        ),
      };
    }

    return {
      listHeader: <IsinListDefaultHeader priceTicksEnabled={priceTicksEnabled} />,
      listRow: (
        <TurboRow
          key={'key'}
          style={{}}
          turbo={instrument as Turbo}
          selectMarket={selectMarket}
          selectedMarketId={selectedMarketId}
          priceTicksEnabled={priceTicksEnabled}
        />
      ),
    };
  };

  const { listHeader, listRow } = getListComponents(instrument);

  return (
    <div data-testid="isin-grid" className={gridStyles.gridWrapperScrollable}>
      {listHeader}
      <ul className={gridStyles.gridAdvancedSearch}>{!isLoading && instrument && listRow}</ul>
    </div>
  );
};

export default IsinList;

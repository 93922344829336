import { Constant, Turbo } from '../components/MtfScreener';

export type IsinTabState = {
  error: Error | string | null;
  isLoading: boolean;
  searchPhrase: string;
  instrument: Constant | Turbo | null;
};

export type Action =
  | { type: 'LOADING_START' }
  | {
      type: 'LOADING_COMPLETE';
      data: {
        instruments: Constant[] | Turbo[];
      };
    }
  | { type: 'SET_SEARCH_PHRASE'; searchPhrase: string }
  | { type: 'RESET_INSTRUMENT' }
  | { type: 'ERROR'; error: Error | string | null };

const isinReducer = (state: IsinTabState, action: Action): IsinTabState => {
  switch (action.type) {
    case 'LOADING_START':
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case 'LOADING_COMPLETE':
      const {
        data: { instruments },
      } = action;

      return {
        ...state,
        isLoading: false,
        error: null,
        instrument: instruments[0],
      };

    case 'SET_SEARCH_PHRASE':
      const { searchPhrase } = action;
      return {
        ...state,
        searchPhrase,
      };

    case 'RESET_INSTRUMENT':
      return {
        ...state,
        error: null,
        isLoading: false,
        searchPhrase: '',
        instrument: null,
      };

    case 'ERROR':
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };

    /* istanbul ignore next */
    default:
      return state;
  }
};

export default isinReducer;

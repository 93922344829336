import { UnderlyingListItem } from '../components/Filters';

export type UnderlyingFromAPI = {
  isChecked: boolean;
  id: string;
  name: string;
};

export type UnderlyingListItemFromAPI = {
  categoryId: string;
  categoryName: string;
  underlyingMarkets: UnderlyingFromAPI[];
};

export const mapFiltersData = (filters: UnderlyingListItemFromAPI[]): UnderlyingListItem[] => {
  return filters.map((filter: UnderlyingListItemFromAPI) => {
    return {
      category: filter.categoryId,
      categoryName: filter.categoryName,
      underlyings: filter.underlyingMarkets.map((ul: UnderlyingFromAPI) => {
        return {
          isChecked: ul.isChecked,
          underlyingId: ul.id,
          underlyingName: ul.name,
        };
      }),
    };
  });
};

import React from 'react';
// @ts-ignore
import styles from '../styles/grid-header.module.css';
import { useTranslation } from 'react-i18next';

export type IsinListWarrantHeaderProps = {
  priceTicksEnabled: boolean;
};

const IsinListWarrantHeader: React.FC<IsinListWarrantHeaderProps> = ({ priceTicksEnabled }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.gridHeader}>
      <div
        className={`${styles.gridHeaderItem} ${styles.gridHeaderMarket} ${styles.gridHeaderItemNonSortable}`}
        data-testid="instrumentNameHeader"
        title={t('underlying.label-long')}
      >
        {t('underlying.label-short')}
      </div>
      <div
        className={`${styles.gridHeaderItem} ${styles.gridHeaderDirection} ${styles.gridHeaderItemNonSortable}`}
        data-testid="directionHeader"
        title={t('direction.label-long')}
      >
        {t('direction.label-short')}
      </div>
      <div
        className={`${styles.gridHeaderItem}  ${styles.gridHeaderStrikeLevel} ${styles.gridHeaderItemNonSortable}`}
        title={t('strike.label-long')}
      >
        {t('strike.label-short')}
      </div>
      <div
        className={`${styles.gridHeaderItem} ${styles.gridHeaderUnderlyingLevel} ${styles.gridHeaderItemNonSortable}`}
        title={t('underlying-level.label-long')}
      >
        {t('underlying-level.label-short')}
      </div>
      <div
        className={`${styles.gridHeaderItem} ${styles.gridHeaderExpiry} ${styles.gridHeaderItemNonSortable}`}
        title={t('expiry.label-long')}
      >
        {t('expiry.label-short')}
      </div>
      <div
        className={`${styles.gridHeaderItem} ${styles.gridHeaderSell} ${
          priceTicksEnabled ? styles.gridHeaderSellBuyWithPriceTicks : ''
        }`}
        title={t('list-header.sell')}
      >
        {t('list-header.sell')}
      </div>
      <div
        className={`${styles.gridHeaderItem} ${styles.gridHeaderBuy} ${
          priceTicksEnabled ? styles.gridHeaderSellBuyWithPriceTicks : ''
        }`}
        title={t('list-header.buy')}
      >
        {t('list-header.buy')}
      </div>
    </div>
  );
};

export default IsinListWarrantHeader;

import React, { RefObject, useRef, useState } from 'react';
// @ts-ignore
import styles from '../styles/single-select-dropdown.module.css';
import useClickOutside from 'click-outside';
import { UnderlyingSort } from '../hooks/use-underlying-market';
import { useTranslation } from 'react-i18next';

export type DropdownItem = {
  displayString: string;
  key: string;
};

export type SingleSelectDropdownProps = {
  items: DropdownItem[];
  onSelect: Function;
  placeholder: string;
  classForAnchor: string;
  selectedItemKey: string;
};

const SingleSelectDropdown: React.FC<SingleSelectDropdownProps> = ({
  items,
  onSelect,
  placeholder,
  classForAnchor,
  selectedItemKey,
}) => {
  const { t } = useTranslation();
  const dropdownRef: RefObject<HTMLDivElement> = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = () => {
    /* istanbul ignore else */
    if (isOpen) {
      setIsOpen(false);
    }
  };

  useClickOutside(dropdownRef, handleClickOutside);

  const getSortingLabel = (sort: UnderlyingSort) => {
    switch (sort) {
      case UnderlyingSort.popularity:
        return t('sorting.popularity');
      case UnderlyingSort.asc:
        return t('sorting.asc');
      default:
        return t('sorting.desc');
    }
  };

  const list =
    !items || items.length === 0 ? (
      <li className="filters-multiselect_none">{t('underlying.none-available')}</li>
    ) : (
      items.map((item: DropdownItem) => (
        <li key={item.key} className={styles.menuItemGroup}>
          <div
            data-testid={`single-select-${item.key}`}
            className={`${styles.menuItem} ${
              item.key === selectedItemKey ? styles.menuItemSelected : ''
            }`}
            onClick={() => {
              onSelect(item.key);
              setIsOpen(false);
            }}
          >
            <div>
              <span className={styles.menuLabel}>{item.displayString}</span>
            </div>
          </div>
        </li>
      ))
    );

  return (
    <div className={classForAnchor} ref={dropdownRef}>
      <div onClick={toggleDropdown} data-testid="single-select-trigger">
        {placeholder}&nbsp;(
        <span className={styles.sortingLabel}>
          {getSortingLabel(selectedItemKey as UnderlyingSort)}
        </span>
        )
        <span
          className={`${styles.sortableIcon} ${
            selectedItemKey === UnderlyingSort.asc ? styles.sortableIconAsc : ''
          }`}
        ></span>
        <span></span>
      </div>
      {isOpen && (
        <ul className={styles.listWrapper} data-testid="single-select-dropdown">
          {list}
        </ul>
      )}
    </div>
  );
};

export default SingleSelectDropdown;

import React from 'react';
// @ts-ignore
import styles from '../styles/numeric-input.module.css';

export type NumericInputProps = {
  name?: string | undefined;
  placeholder?: string;
  value?: number | undefined;
  min?: number | undefined;
  max?: number | undefined;
  step?: number | undefined;
  onChange: (n: number | undefined) => void;
  tabIndex?: number;
  error?: boolean;
};

const NumericInput: React.FC<NumericInputProps> = ({
  name,
  placeholder,
  value,
  min = 0,
  max = Infinity,
  step = 1,
  onChange,
  tabIndex,
  error,
}) => {
  const inputEll = React.createRef<HTMLInputElement>();
  const isNegativeAllowed = min < 0;
  const stepUp = () => {
    let newValue = undefined;
    if (value === undefined) {
      // nothing is set
      if (min !== undefined && min > step) {
        // if a minimum exists, and it is higher than 'step' we should set to the minimum
        newValue = min;
      } else {
        // going down would mean set to an appropriate starting value
        newValue = step;
      }
    } else {
      // otherwise it must be set to the max allowed
      newValue = value + step;
    }
    onChange(newValue);
    setToFocus();
  };

  const stepDown = () => {
    let newValue = undefined;
    if (value === undefined) {
      // the input field is not set
      if (min !== undefined && min > step) {
        // if a max exists and it's more than 1 step away, set to max
        newValue = min;
      } else {
        // going down would mean set to an appropriate starting value
        newValue = step;
      }
    } else {
      newValue = value - step;
    }
    onChange(newValue);
    setToFocus();
  };

  // Set the focus back on the input element after clicking the incrementor buttons
  const setToFocus = () => {
    inputEll?.current?.focus();
  };

  const shouldBeDisabled = (direction: '+' | '-', currVal: number | undefined, range: number) => {
    if (direction === '+') {
      if (currVal !== undefined) {
        return currVal + step > range;
      }
      return false;
    } else {
      if (currVal !== undefined) {
        return currVal - step < range;
      }
      return false;
    }
  };

  const numericInputClasses = `${styles.numericInputWithIncrementorsInput} ${styles.formControl} ${
    error ? styles.formControlDanger : ''
  }`;
  const pulseButtonUpClasses = `${styles.numericInputWithIncrementorsIncrement}`;
  const pulseButtonDownClasses = `${styles.numericInputWithIncrementorsDecrement}`;

  return (
    <div
      className={styles.numericInputWithIncrementors}
      data-testid="numeric-input-with-incrementors"
    >
      <input
        autoComplete="off"
        placeholder={placeholder}
        name={name}
        step={step}
        value={value === undefined ? '' : value}
        min={min}
        max={max}
        className={numericInputClasses}
        type="number"
        tabIndex={tabIndex}
        data-testid={`numeric-input-${tabIndex}`}
        onChange={(e) => {
          const inputValue = e.target.value;
          if (inputValue === '') {
            onChange(undefined);
          } else {
            onChange(+inputValue);
          }
        }}
        onKeyDown={(e) => {
          if (isNegativeAllowed) {
            return true;
          } else {
            if (e.key === '-') {
              e.preventDefault();
              return false;
            }
            return true;
          }
        }}
        ref={inputEll as React.RefObject<HTMLInputElement>}
      />
      <div className={styles.numericInputWithIncrementorsIconsBg}></div>
      <button
        className={pulseButtonUpClasses}
        onClick={stepUp}
        disabled={shouldBeDisabled('+', value, max)}
        data-testid="pulse-button-up"
      >
        +
      </button>
      <button
        className={pulseButtonDownClasses}
        onClick={stepDown}
        disabled={shouldBeDisabled('-', value, min)}
        data-testid="pulse-button-down"
      >
        -
      </button>
      {error}
    </div>
  );
};

export default NumericInput;

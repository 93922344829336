import React, { useEffect, useMemo, useReducer } from 'react';
// @ts-ignore
import styles from '../styles/filters.module.css';
// @ts-ignore
import datepickerStyles from '../styles/datepicker.module.css';
import '../styles/react-datepicker.module.css';
import MultiSelectDropdown from './MultiSelectDropdown';
import useUnderlyings from '../hooks/use-underlyings';
import warrantFiltersReducer from '../hooks/warrant-filters-reducer';
import NumericInput from './NumericInput';
import SearchResult from './SearchResult';
import { SearchTab } from './Tabs';
import { useDebouncedEffect } from '../utils/debounce-utils';
import { useDebouncedCallback } from 'use-debounce';
import { FILTER_UPDATE_DELAY } from './FilterTimeout';
import { WarrantSortableFields } from '../hooks/use-warrant';
import format from 'date-fns/format';
import { useTranslation } from 'react-i18next';
import ExpiryInput from './ExpiryInput';
import { useWebsiteScreenerParams } from '../hooks/use-website-screener-params';
import { REGISTERED_LOCALES } from '../root.component';

export const EXPIRY_DATE_FORMAT = 'dd/MM/yy';
export const EXPIRY_FILTER_PILL_DATE_FORMAT = 'd MMMM yyyy';
export const EXPIRY_MIN_DATE = new Date();

export enum WarrantFilterInteraction {
  direction = 'Direction',
  enable = 'Enable',
  expiryMax = 'Expiry max',
  expiryMin = 'Expiry min',
  disable = 'Disable',
  koLevelMax = 'KO Level max',
  koLevelMin = 'KO Level min',
  reset = 'Reset',
}

export enum Direction {
  ALL = 'All',
  SHORT = 'Short',
  LONG = 'Long',
}

export type ActiveFilter = {
  id: string;
  name: string;
  type: string;
};

export type Underlying = {
  isChecked: boolean;
  underlyingId: string;
  underlyingName: string;
};

export type UnderlyingListItem = {
  category: string;
  categoryName: string;
  underlyings: Underlying[];
};

export type WarrantFiltersState = {
  underlyings: UnderlyingListItem[];
  direction: Direction;
  expiryMax: number | undefined;
  expiryMin: number | undefined;
  koLevelMax: number | undefined;
  koLevelMin: number | undefined;
  error?: Error | string | null;
};

export type WarrantSortingState = {
  sortBy: WarrantSortableFields;
  sortAsc: boolean;
};

export type WarrantFiltersProps = {
  filters: WarrantFiltersState;
  preselectedUnderlyings: Underlying[];
  totalResults: number;
  updateFilters: Function;
  trackFilterInteraction: Function;
  error: string | Error | null;
  isLoading: boolean;
};

export const DEFAULT_FILTER_STATE = {
  direction: Direction.ALL,
  expiryMax: undefined,
  expiryMin: undefined,
  koLevelMin: undefined,
  koLevelMax: undefined,
  underlyings: [],
};

export const filtersApplied = (state: WarrantFiltersState): boolean => {
  return (
    state.direction !== Direction.ALL ||
    [state.koLevelMin, state.koLevelMax, state.expiryMin, state.expiryMax].some(
      (v) => v !== undefined
    ) ||
    state.underlyings
      .reduce(
        (result: Underlying[], currentValue: UnderlyingListItem) =>
          result.concat(currentValue.underlyings),
        []
      )
      .some((u) => u.isChecked)
  );
};

const WarrantFilters: React.FC<WarrantFiltersProps> = ({
  filters,
  preselectedUnderlyings,
  totalResults,
  updateFilters,
  trackFilterInteraction,
  error,
  isLoading,
}) => {
  const { t } = useTranslation();
  const { isWebsiteTheme } = useWebsiteScreenerParams();

  const [state, dispatch] = useReducer(warrantFiltersReducer, filters);

  const cachedPreselectedUnderlyings = useMemo(
    () => preselectedUnderlyings,
    [preselectedUnderlyings]
  );

  const { underlyings } = useUnderlyings(cachedPreselectedUnderlyings);

  useEffect(() => {
    /* istanbul ignore else */
    if (underlyings) {
      dispatch({
        type: 'SET_UNDERLYINGS',
        data: underlyings,
      });
    }
  }, [underlyings]);

  /* istanbul ignore next */
  useDebouncedEffect(
    () => {
      // we only want to reset the filters if they have actually changed
      // This prevents us making a second request when the underlyings load and there is no change to the saved filters
      if (JSON.stringify(filters) !== JSON.stringify(state)) {
        return updateFilters(state);
      }
    },
    FILTER_UPDATE_DELAY,
    state
  );

  /*
   * Handle filters changes
   */
  const onToggleUnderlying = (type: string, id: string, currentlyChecked: boolean) => {
    dispatch({
      type: 'TOGGLE_UNDERLYING_ITEM',
      underlyingType: type,
      underlyingId: id,
    });

    const groupedUnderlying = state.underlyings.find(
      (item: UnderlyingListItem) => item.category === type
    );
    // @ts-ignore
    const selectedUnderlying = groupedUnderlying.underlyings.find(
      (item: Underlying) => item.underlyingId === id
    );

    trackFilterInteraction(
      currentlyChecked ? WarrantFilterInteraction.disable : WarrantFilterInteraction.enable,
      selectedUnderlying?.underlyingId,
      selectedUnderlying?.underlyingName
    );
  };

  const onUpdateDirection = (direction: Direction) => {
    dispatch({
      type: 'UPDATE_DIRECTION',
      direction,
    });
    trackFilterInteraction(WarrantFilterInteraction.direction, direction);
  };

  const onUpdateNumericFilters = (
    key: keyof typeof WarrantFilterInteraction,
    value: number | undefined
  ) => {
    debouncedTrackFilterInteraction(WarrantFilterInteraction[key], value);
    dispatch({ type: 'UPDATE_NUMERIC_FILTER', key, value });
  };

  const onUpdateExpiryFilters = (
    key: keyof typeof WarrantFilterInteraction,
    value: number | undefined
  ) => {
    debouncedTrackFilterInteraction(WarrantFilterInteraction[key], value);
    dispatch({ type: 'UPDATE_EXPIRY_FILTER', key, value });
  };

  // Debouncer to track filter interactions when the user inc- or decrement
  // the numeric fields continuously under the FILTER_UPDATE_DELAY time
  const debouncedTrackFilterInteraction = useDebouncedCallback((interaction, value) => {
    trackFilterInteraction(interaction, `${value}`);
  }, FILTER_UPDATE_DELAY);

  const onRemoveFilter = (activeFilter: ActiveFilter) => {
    switch (activeFilter.type) {
      case 'direction':
        onUpdateDirection(Direction.ALL);
        break;
      case 'expiry':
        onUpdateExpiryFilters('expiryMin', undefined);
        onUpdateExpiryFilters('expiryMax', undefined);
        break;
      case 'strike':
        onUpdateNumericFilters('koLevelMin', undefined);
        onUpdateNumericFilters('koLevelMax', undefined);
        break;
      default:
        onToggleUnderlying(activeFilter.type, activeFilter.id, true);
    }
  };

  const onResetFilters = () => {
    dispatch({
      type: 'RESET_FILTERS',
    });
    trackFilterInteraction(WarrantFilterInteraction.reset);
  };

  const getActiveFilters = () => {
    const allFilters = state.underlyings.reduce(
      (result: ActiveFilter[], currentValue: UnderlyingListItem) => {
        const mappedFilters: ActiveFilter[] = currentValue.underlyings
          .filter((item: Underlying) => item.isChecked)
          .map((item: Underlying) => ({
            id: item.underlyingId,
            name: item.underlyingName,
            type: currentValue.category,
          }));

        return result.concat(mappedFilters);
      },
      []
    );

    if (state.direction !== Direction.ALL) {
      allFilters.push({
        id: 'direction',
        name: t(
          state.direction === Direction.LONG ? 'filters.pills.call-only' : 'filters.pills.put-only'
        ),
        type: 'direction',
      });
    }

    [
      {
        filter: 'strike',
        min: state.koLevelMin,
        max: state.koLevelMax,
        type: 'koLevel',
      },
      {
        filter: 'expiry',
        min: !state.expiryMin
          ? state.expiryMin
          : format(state.expiryMin, EXPIRY_FILTER_PILL_DATE_FORMAT, {
              locale: REGISTERED_LOCALES[window.__localeId__],
            }),
        max: !state.expiryMax
          ? state.expiryMax
          : format(state.expiryMax, EXPIRY_FILTER_PILL_DATE_FORMAT, {
              locale: REGISTERED_LOCALES[window.__localeId__],
            }),
      },
    ].forEach(({ filter, min, max }) => {
      const haveMin = min !== undefined;
      const haveMax = max !== undefined;

      if (haveMin || haveMax) {
        let filterDisplayText = undefined;

        if (haveMax && !haveMin) {
          filterDisplayText = 'less-than';
        } else if (haveMin && !haveMax) {
          filterDisplayText = 'more-than';
        } else if (haveMin && haveMax && min === max) {
          filterDisplayText = 'exactly';
        } else {
          filterDisplayText = 'in-range';
        }

        allFilters.push({
          id: filter,
          name: t(`filters.pills.${filterDisplayText}`, {
            filter: t(`${filter}.label-long`),
            min: `${haveMin ? min : ''}`,
            max: `${haveMax ? max : ''}`,
            minUnit: '',
            maxUnit: '',
          }),
          type: filter,
        });
      }
    });
    return allFilters;
  };

  const activeFilters = getActiveFilters();

  const underlyingFilters = state.underlyings.length
    ? state.underlyings.map((item: UnderlyingListItem) => (
        <MultiSelectDropdown
          placeholder={t(`underlying.${item.category.toLowerCase()}`)}
          items={item.underlyings}
          key={item.category}
          onToggleItem={(id: string, currentlyChecked: boolean) =>
            onToggleUnderlying(item.category, id, currentlyChecked)
          }
        />
      ))
    : t('underlying.none-available');

  const getDirectionClass = (direction: Direction) => {
    switch (direction) {
      case Direction.ALL:
        return styles.segmentedControlAll;
      case Direction.SHORT:
        return styles.segmentedControlShort;
      case Direction.LONG:
        return styles.segmentedControlLong;
    }
  };

  return (
    <>
      <div className={styles.filters} data-testid="screener-filters">
        <div className={styles.gridWrapper}>
          <div className={styles.gridUnderlyings}>
            <div className={styles.label}>{t('underlying.label-filter')}</div>
            <div className={`${styles.filtersSection} ${styles.filtersSectionUnderlyings}`}>{underlyingFilters}</div>
          </div>
          <div className={styles.gridDirection}>
            <div className={styles.label}>{t('direction.label-long')}</div>
            <div className={styles.filtersSection}>
              <ul className={`${styles.segmentedControl} ${getDirectionClass(state.direction)}`}>
                <li
                  className={`${styles.segmentedControlSegment} ${
                    state.direction === Direction.ALL ? styles.segmentedControlSegmentActive : ''
                  }`}
                  onClick={() => onUpdateDirection(Direction.ALL)}
                  title={t('direction-warrant.all')}
                  data-testid="filter-direction-all"
                >
                  <div className={styles.segmentedControlLabel}>{t('direction-warrant.all')}</div>
                </li>
                <li
                  className={`${styles.segmentedControlSegment} ${
                    state.direction === Direction.SHORT ? styles.segmentedControlSegmentActive : ''
                  }`}
                  onClick={() => onUpdateDirection(Direction.SHORT)}
                  title={t('direction-warrant.put')}
                  data-testid="filter-direction-put"
                >
                  <div className={styles.segmentedControlLabel}>{t('direction-warrant.put')}</div>
                </li>
                <li
                  className={`${styles.segmentedControlSegment} ${
                    state.direction === Direction.LONG ? styles.segmentedControlSegmentActive : ''
                  }`}
                  onClick={() => onUpdateDirection(Direction.LONG)}
                  title={t('direction-warrant.call')}
                  data-testid="filter-direction-call"
                >
                  <div className={styles.segmentedControlLabel}>{t('direction-warrant.call')}</div>
                </li>
              </ul>
            </div>
          </div>
          <div className={styles.gridStrike} data-testid="filters-grid-ko-level">
            <div className={styles.label}>{t('strike.label-long')}</div>
            <div className={`${styles.filtersSection} ${styles.filtersSectionInput}`}>
              <NumericInput
                placeholder={t('filters.min')}
                value={state.koLevelMin}
                min={0}
                max={state.koLevelMax || Infinity}
                tabIndex={1}
                onChange={(value) => onUpdateNumericFilters('koLevelMin', value)}
              />
              <div className={styles.filtersSeparator}>{t('filters.to-separator')}</div>
              <NumericInput
                placeholder={t('filters.max')}
                value={state.koLevelMax}
                min={state.koLevelMin || 0}
                max={Infinity}
                tabIndex={2}
                onChange={(value) => onUpdateNumericFilters('koLevelMax', value)}
              />
            </div>
          </div>
          <div className={styles.gridExpiry} data-testid="filters-grid-expiry">
            <div className={styles.label}>{t('expiry.label-long')}</div>
            <div className={`${styles.filtersSection} ${styles.filtersSectionInput}`}>
              <ExpiryInput
                className={styles.expiryInput}
                calendarClassName={datepickerStyles.mtfDatepicker}
                dateFormat={EXPIRY_DATE_FORMAT}
                minDate={EXPIRY_MIN_DATE}
                onChange={(date: Date) =>
                  onUpdateExpiryFilters('expiryMin', !date ? undefined : date.getTime())
                }
                placeholderText={t('expiry.placeholder')}
                // DatePicker accepts milliseconds as selected value
                // but the interface doesn't accept type 'number'
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                selected={state.expiryMin as any}
                tabIndex={3}
              />
              <div className={styles.filtersSeparator}>{t('filters.to-separator')}</div>
              <ExpiryInput
                className={styles.expiryInput}
                calendarClassName={datepickerStyles.mtfDatepicker}
                dateFormat={EXPIRY_DATE_FORMAT}
                minDate={EXPIRY_MIN_DATE}
                onChange={(date: Date) =>
                  onUpdateExpiryFilters('expiryMax', !date ? undefined : date.getTime())
                }
                placeholderText={t('expiry.placeholder')}
                // DatePicker accepts milliseconds as selected value
                // but the interface doesn't accept type 'number'
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                selected={state.expiryMax as any}
                tabIndex={4}
              />
            </div>
          </div>
        </div>
        {!isWebsiteTheme && (
          <div
            className={styles.resetButton}
            onClick={() => onResetFilters()}
            data-testid="reset-button"
          >
            {t('filters.reset')}
          </div>
        )}
        {isWebsiteTheme && (
          <button
            type="button"
            className={styles.websiteResetButton}
            onClick={() => onResetFilters()}
            data-testid="website-reset-button"
          >
            {t('filters.reset-filters')}
          </button>
        )}
      </div>
      {isWebsiteTheme && activeFilters.length > 0 && (
        <div className={styles.appliedFiltersLabel}>{t('filters.applied-filters')}</div>
      )}
      <div
        className={`${styles.activeFilters} ${
          activeFilters.length ? '' : styles.activeFiltersEmpty
        }`}
      >
        {activeFilters &&
          activeFilters.map((activeFilter: ActiveFilter) => (
            <div className={styles.activeFiltersItem} key={activeFilter.id}>
              <div className={styles.activeFiltersLabel} data-testid="active-filters-item">
                {activeFilter.name}
              </div>
              <button
                name={t('filters.remove')}
                title={t('filters.remove')}
                type="button"
                className={styles.activeFiltersItemClose}
                data-testid="active-filters-item-close"
                onClick={() => onRemoveFilter(activeFilter)}
              ></button>
            </div>
          ))}
      </div>

      <SearchResult
        totalResults={totalResults}
        error={error}
        instrumentType={null}
        isLoading={isLoading}
        searchTab={SearchTab.WARRANT}
        hasFilters={activeFilters.length > 0}
      />
    </>
  );
};

export default WarrantFilters;

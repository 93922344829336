/**
 * Conditions to round the value into $0.00 format:
 * 1. Value after decimal place is only 0 or 1 figure
 * 2. Value after decimal place has less figures than the whole number portion
 *    eg. 12345.739 -> 12345.74
 *    eg. 12.4      -> 12.40
 * 3. Value after decimal place has more/equal figures than the whole number, but the whole number portion has 3+ figures
 *    eg. 1830.22953 -> 1830.23
 *    eg. 130.2293 -> 130.23
 *
 * @param n number to be presented
 */

export const presentNumber = (n: number) => {
    return shouldBeRounded(n) ? n.toFixed(2) : n.toString();
};

const shouldBeRounded = (n: number) => {
    const [leftPart, rightPart] = n.toString().split('.');

    /* istanbul ignore else */
    if (leftPart && rightPart) {
        return (
            rightPart.length < 2 ||
            rightPart.length < leftPart.length ||
            (rightPart.length >= leftPart.length && leftPart.length >= 3)
        );
    }

    return true;
};

import { useEffect, useReducer } from 'react';
import abortableFetch, { toUrl } from '../utils/fetch';
import {
  INSTRUMENTS_URL,
  INSTRUMENTS_URL_UNAUTHORIZED,
  InstrumentsResponse,
} from '../components/MtfScreener';
import isinReducer from './isin-reducer';
import { isOnlyAlphanumericCharacters } from '../utils/search-utils';
import { SaveIsinTabCallback } from '../components/IsinTab';
import { useActions } from './use-actions';
import { useSessionData } from './use-session-data';
import { useWebsiteScreenerParams } from './use-website-screener-params';
import { getIsinURLParams } from '../utils/url-utils';

export type IsinTabConfig = {
  isinSearch: string;
};

export const ISIN_TAB_SESSION_STORAGE_KEY = 'isin-tab-config';
export const DEFAULT_ISIN_TAB_CONFIG: IsinTabConfig = {
  isinSearch: '',
};

export type IsinURLParams = {
  fields: string;
  isin: string;
  locale?: string;
};

const useIsin = (savedIsinTabConfig: IsinTabConfig, saveIsinTabConfig: SaveIsinTabCallback) => {
  const { allowUnauthorizedAPI, locale } = useWebsiteScreenerParams();
  const { actions } = useActions();
  const { apiHost, cst, currentAccountId, xst } = useSessionData();

  const [state, dispatch] = useReducer(isinReducer, {
    error: null,
    isLoading: false,
    searchPhrase: savedIsinTabConfig.isinSearch,
    instrument: null,
  });

  useEffect(() => {
    const [fetch, abort] = allowUnauthorizedAPI
      ? abortableFetch(null, null)
      : abortableFetch(xst, cst, currentAccountId);

    if (!state.searchPhrase) {
      return () => abort();
    }

    if (!isOnlyAlphanumericCharacters(state.searchPhrase)) {
      dispatch({ type: 'RESET_INSTRUMENT' });
      dispatch({
        type: 'ERROR',
        error: 'isin.invalid-characters',
      });
      return;
    }

    if (state.searchPhrase.length !== 12) {
      dispatch({ type: 'RESET_INSTRUMENT' });
      return;
    }

    const urlParams: IsinURLParams = getIsinURLParams(state.searchPhrase);

    if (allowUnauthorizedAPI) {
      urlParams.locale = locale.slice(0, 5);
    }

    const url = allowUnauthorizedAPI
      ? toUrl(apiHost, `${INSTRUMENTS_URL_UNAUTHORIZED}`, urlParams)
      : toUrl(apiHost, `${INSTRUMENTS_URL}`, urlParams);

    if (actions.onSearch) {
      actions.onSearch(state.searchPhrase, 'ISIN');
    }

    (async () => {
      try {
        dispatch({ type: 'LOADING_START' });

        const response = await fetch(url, { method: 'get' });

        if (!response.ok) {
          dispatch({ type: 'ERROR', error: response.statusText });
          return;
        }

        const json: InstrumentsResponse = await response.json();

        dispatch({
          type: 'LOADING_COMPLETE',
          data: {
            instruments: json.results.instruments,
          },
        });
      } catch (error) /* istanbul ignore next */ {
        if (error.name === 'AbortError') {
          return;
        }

        dispatch({ type: 'ERROR', error: error.toString() });
      }
    })();

    return () => abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiHost, xst, cst, currentAccountId, state.searchPhrase]);

  return {
    updateSearchPhrase: (searchPhrase: string) => {
      dispatch({ type: 'SET_SEARCH_PHRASE', searchPhrase });
      saveIsinTabConfig({
        isinSearch: searchPhrase,
      });
    },
    resetInstrument: () => {
      dispatch({ type: 'RESET_INSTRUMENT' });
    },
    ...state,
  };
};

export default useIsin;

import React from 'react';
// @ts-ignore
import styles from '../styles/grid-header.module.css';
import { useTranslation } from 'react-i18next';

export type IsinListConstantHeaderProps = {
  priceTicksEnabled: boolean;
};

const IsinListConstantHeader: React.FC<IsinListConstantHeaderProps> = ({ priceTicksEnabled }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.gridHeader}>
      <div
        className={`${styles.gridHeaderItem} ${styles.gridConstantHeaderMarket} ${styles.gridHeaderItemNonSortable}`}
        data-testid="instrumentNameHeader"
        title={t('underlying.label-long')}
      >
        {t('underlying.label-short')}
      </div>
      <div
        className={`${styles.gridHeaderItem} ${styles.gridHeaderDirection} ${styles.gridHeaderItemNonSortable}`}
        data-testid="directionHeader"
        title={t('direction.label-long')}
      >
        {t('direction.label-short')}
      </div>
      <div
        className={`${styles.gridHeaderItem} ${styles.gridHeaderLeverageFactor} ${styles.gridHeaderItemNonSortable}`}
        title={t('leverageFactor.label-long')}
      >
        {t('leverageFactor.label-short')}
      </div>
      <div
        className={`${styles.gridHeaderItem} ${styles.gridHeaderConstantSell} ${
          priceTicksEnabled ? styles.gridHeaderConstantSellWithPriceTicks : ''
        }`}
        title={t('list-header.sell')}
      >
        {t('list-header.sell')}
      </div>
      <div
        className={`${styles.gridHeaderItem} ${styles.gridHeaderBuy} ${
          priceTicksEnabled ? styles.gridHeaderSellBuyWithPriceTicks : ''
        }`}
        title={t('list-header.buy')}
      >
        {t('list-header.buy')}
      </div>
    </div>
  );
};

export default IsinListConstantHeader;

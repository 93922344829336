import { UnderlyingSort } from './use-underlying-market';
import { SearchUnderlyingState } from '../components/UnderlyingTab';
import { UnderlyingMarket } from '../components/MtfScreener';

export type Action =
  | {
      type: 'LOADING_COMPLETE';
      instruments: UnderlyingMarket[];
      pageNumber: number;
      totalPages: number;
      totalResults: number;
    }
  | {
      type: 'SET_PAGINATION_DATA';
      paginationData: { totalPages: number; totalResults: number };
    }
  | { type: 'ERROR'; error: Error | string | null }
  | { type: 'SET_QUERY'; query: string }
  | { type: 'SET_SORT'; sort: UnderlyingSort };

const underlyingMarketReducer = (
  state: SearchUnderlyingState,
  action: Action
): SearchUnderlyingState => {
  switch (action.type) {
    case 'LOADING_COMPLETE':
      const { totalPages, instruments, totalResults } = action;

      if (totalResults === 0 && totalPages === 1) {
        state.data = [];
      } else {
        instruments.forEach((market) => {
          market.epic = market.referenceEpic;
        });
        state.data = instruments;
      }

      return {
        ...state,
        isLoading: false,
        data: state.data,
        error: null,
        totalPages: action.totalPages,
        totalResults: action.totalResults,
      };

    case 'ERROR':
      return {
        ...state,
        error: action.error,
        isLoading: false,
        data: [],
      };

    case 'SET_QUERY':
      return {
        ...state,
        isLoading: true,
        data: [],
        underlyingSearch: action.query,
        pageNumber: 0,
        totalPages: 0,
        totalResults: 0,
      };
    case 'SET_SORT':
      return {
        ...state,
        isLoading: true,
        data: [],
        sortPreference: action.sort,
        pageNumber: 0,
        totalPages: 0,
        totalResults: 0,
      };

    /* istanbul ignore next */
    default:
      return state;
  }
};

export default underlyingMarketReducer;

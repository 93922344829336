export const calculateMid = (
  ask: number | undefined,
  bid: number | undefined
): number | undefined => {
  if (!ask && ask !== 0 && !bid && bid !== 0) {
    return undefined;
  }

  if (!ask && ask !== 0) {
    return bid;
  }

  if (!bid && bid !== 0) {
    return ask;
  }

  return Number(((bid + ask) / 2).toFixed(2));
};

import React, { useEffect, useMemo, useReducer } from 'react';
// @ts-ignore
import styles from '../styles/filters.module.css';
import MultiSelectDropdown from './MultiSelectDropdown';
import useUnderlyings from '../hooks/use-underlyings';
import constantFiltersReducer from '../hooks/constant-filters-reducer';
import SearchResult from './SearchResult';
import { SearchTab } from './Tabs';
import { useDebouncedEffect } from '../utils/debounce-utils';
import { useDebouncedCallback } from 'use-debounce';
import { FILTER_UPDATE_DELAY } from './FilterTimeout';
import { ConstantSortableFields } from '../hooks/use-constant';
import NumericInput from './NumericInput';
import { useTranslation } from 'react-i18next';
import { TrackFilterInteractionCallback } from './MtfScreener';
import { useWebsiteScreenerParams } from '../hooks/use-website-screener-params';

export enum ConstantFilterInteraction {
  direction = 'Direction',
  enable = 'Enable',
  disable = 'Disable',
  reset = 'Reset',
  leverageFactorMax = 'Leverage Factor max',
  leverageFactorMin = 'Leverage Factor min',
}

export enum Direction {
  ALL = 'All',
  SHORT = 'Short',
  LONG = 'Long',
}

export type UpdateConstantFiltersCallback = (filters: ConstantFiltersState) => void;

export type ActiveFilter = {
  id: string;
  name: string;
  type: string;
};

export type Underlying = {
  isChecked: boolean;
  underlyingId: string;
  underlyingName: string;
};

export type UnderlyingListItem = {
  category: string;
  categoryName: string;
  underlyings: Underlying[];
};

export type ConstantFiltersState = {
  underlyings: UnderlyingListItem[];
  direction: Direction;
  leverageFactorMax: number | undefined;
  leverageFactorMin: number | undefined;
  error?: Error | string | null;
};

export type ConstantSortingState = {
  sortBy: ConstantSortableFields;
  sortAsc: boolean;
};

export type ConstantFiltersProps = {
  filters: ConstantFiltersState;
  preselectedUnderlyings: Underlying[];
  totalResults: number;
  updateFilters: UpdateConstantFiltersCallback;
  trackFilterInteraction: TrackFilterInteractionCallback;
  error: string | Error | null;
  isLoading: boolean;
};

export const DEFAULT_FILTER_STATE = {
  direction: Direction.ALL,
  leverageFactorMax: undefined,
  leverageFactorMin: undefined,
  underlyings: [],
};

export const filtersApplied = (state: ConstantFiltersState): boolean => {
  return (
    state.direction !== Direction.ALL ||
    [state.leverageFactorMin, state.leverageFactorMax].some((v) => v !== undefined) ||
    state.underlyings
      .reduce(
        (result: Underlying[], currentValue: UnderlyingListItem) =>
          result.concat(currentValue.underlyings),
        []
      )
      .some((u) => u.isChecked)
  );
};

const ConstantFilters: React.FC<ConstantFiltersProps> = ({
  filters,
  preselectedUnderlyings,
  totalResults,
  updateFilters,
  trackFilterInteraction,
  error,
  isLoading,
}) => {
  const { t } = useTranslation();
  const { isWebsiteTheme } = useWebsiteScreenerParams();

  const [state, dispatch] = useReducer(constantFiltersReducer, filters);

  const cachedPreselectedUnderlyings = useMemo(
    () => preselectedUnderlyings,
    [preselectedUnderlyings]
  );

  const { underlyings } = useUnderlyings(cachedPreselectedUnderlyings);

  useEffect(() => {
    /* istanbul ignore else */
    if (underlyings) {
      dispatch({
        type: 'SET_UNDERLYINGS',
        data: underlyings,
      });
    }
  }, [underlyings]);

  /* istanbul ignore next */
  useDebouncedEffect(
    () => {
      // we only want to reset the filters if they have actually changed
      // This prevents us making a second request when the underlyings load and there is no change to the saved filters
      if (JSON.stringify(filters) !== JSON.stringify(state)) {
        return updateFilters(state);
      }
    },
    FILTER_UPDATE_DELAY,
    state
  );

  /*
   * Handle filters changes
   */
  const onToggleUnderlying = (type: string, id: string, currentlyChecked: boolean) => {
    dispatch({
      type: 'TOGGLE_UNDERLYING_ITEM',
      underlyingType: type,
      underlyingId: id,
    });

    const groupedUnderlying = state.underlyings.find(
      (item: UnderlyingListItem) => item.category === type
    );
    // @ts-ignore
    const selectedUnderlying = groupedUnderlying.underlyings.find(
      (item: Underlying) => item.underlyingId === id
    );

    trackFilterInteraction(
      currentlyChecked ? ConstantFilterInteraction.disable : ConstantFilterInteraction.enable,
      selectedUnderlying?.underlyingId,
      selectedUnderlying?.underlyingName
    );
  };

  const onUpdateDirection = (direction: Direction) => {
    dispatch({
      type: 'UPDATE_DIRECTION',
      direction,
    });
    trackFilterInteraction(ConstantFilterInteraction.direction, direction, undefined);
  };

  const onUpdateNumericFilters = (
    key: keyof typeof ConstantFilterInteraction,
    value: number | undefined
  ) => {
    debouncedTrackFilterInteraction(ConstantFilterInteraction[key], value);
    dispatch({ type: 'UPDATE_NUMERIC_FILTER', key, value });
  };

  // Debouncer to track filter interactions when the user inc- or decrement
  // the numeric fields continuously under the FILTER_UPDATE_DELAY time
  const debouncedTrackFilterInteraction = useDebouncedCallback((interaction, value) => {
    trackFilterInteraction(interaction, value, undefined);
  }, FILTER_UPDATE_DELAY);

  const onRemoveFilter = (activeFilter: ActiveFilter) => {
    switch (activeFilter.type) {
      case 'direction':
        onUpdateDirection(Direction.ALL);
        break;
      case 'leverageFactor':
        onUpdateNumericFilters('leverageFactorMin', undefined);
        onUpdateNumericFilters('leverageFactorMax', undefined);
        break;
      default:
        onToggleUnderlying(activeFilter.type, activeFilter.id, true);
    }
  };

  const onResetFilters = () => {
    dispatch({
      type: 'RESET_FILTERS',
    });
    trackFilterInteraction(ConstantFilterInteraction.reset, undefined, undefined);
  };

  const getActiveFilters = () => {
    const allFilters = state.underlyings.reduce(
      (result: ActiveFilter[], currentValue: UnderlyingListItem) => {
        const mappedFilters: ActiveFilter[] = currentValue.underlyings
          .filter((item: Underlying) => item.isChecked)
          .map((item: Underlying) => ({
            id: item.underlyingId,
            name: item.underlyingName,
            type: currentValue.category,
          }));

        return result.concat(mappedFilters);
      },
      []
    );

    if (state.direction !== Direction.ALL) {
      allFilters.push({
        id: 'direction',
        name: t(
          state.direction === Direction.LONG
            ? 'filters.pills.long-only'
            : 'filters.pills.short-only'
        ),
        type: 'direction',
      });
    }

    [
      {
        filter: 'leverageFactor',
        min: state.leverageFactorMin,
        max: state.leverageFactorMax,
      },
    ].forEach(({ filter, min, max }) => {
      const haveMin = min !== undefined;
      const haveMax = max !== undefined;

      if (haveMin || haveMax) {
        let filterDisplayText = undefined;

        if (haveMax && !haveMin) {
          filterDisplayText = 'less-than';
        } else if (haveMin && !haveMax) {
          filterDisplayText = 'more-than';
        } else if (haveMin && haveMax && min === max) {
          filterDisplayText = 'exactly';
        } else {
          filterDisplayText = 'in-range';
        }

        allFilters.push({
          id: filter,
          name: t(`filters.pills.${filterDisplayText}`, {
            filter: t(`${filter}.label-short`),
            min: `${haveMin ? min : ''}`,
            max: `${haveMax ? max : ''}`,
            minUnit: '',
            maxUnit: '',
          }),
          type: filter,
        });
      }
    });

    return allFilters;
  };

  const activeFilters = getActiveFilters();

  const underlyingFilters = state.underlyings.length
    ? state.underlyings.map((item: UnderlyingListItem) => (
        <MultiSelectDropdown
          placeholder={t(`underlying.${item.category.toLowerCase()}`)}
          items={item.underlyings}
          key={item.category}
          onToggleItem={(id: string, currentlyChecked: boolean) =>
            onToggleUnderlying(item.category, id, currentlyChecked)
          }
        />
      ))
    : t('underlying.none-available');

  const getDirectionClass = (direction: Direction) => {
    switch (direction) {
      case Direction.ALL:
        return styles.segmentedControlAll;
      case Direction.SHORT:
        return styles.segmentedControlShort;
      case Direction.LONG:
        return styles.segmentedControlLong;
    }
  };

  return (
    <>
      <div className={styles.filters} data-testid="screener-filters">
        <div className={styles.gridWrapper}>
          <div className={styles.gridUnderlyings}>
            <div className={styles.label}>{t('underlying.label-filter')}</div>
            <div className={`${styles.filtersSection} ${styles.filtersSectionUnderlyings}`}>{underlyingFilters}</div>
          </div>
          <div className={styles.gridDirection}>
            <div className={styles.label}>{t('direction.label-long')}</div>
            <div className={styles.filtersSection}>
              <ul className={`${styles.segmentedControl} ${getDirectionClass(state.direction)}`}>
                <li
                  className={`${styles.segmentedControlSegment} ${
                    state.direction === Direction.ALL ? styles.segmentedControlSegmentActive : ''
                  }`}
                  onClick={() => onUpdateDirection(Direction.ALL)}
                  title={t('direction.all')}
                  data-testid="filter-direction-all"
                >
                  <div className={styles.segmentedControlLabel}>{t('direction.all')}</div>
                </li>
                <li
                  className={`${styles.segmentedControlSegment} ${
                    state.direction === Direction.SHORT ? styles.segmentedControlSegmentActive : ''
                  }`}
                  onClick={() => onUpdateDirection(Direction.SHORT)}
                  title={t('direction.short')}
                  data-testid="filter-direction-short"
                >
                  <div className={styles.segmentedControlLabel}>{t('direction.short')}</div>
                </li>
                <li
                  className={`${styles.segmentedControlSegment} ${
                    state.direction === Direction.LONG ? styles.segmentedControlSegmentActive : ''
                  }`}
                  onClick={() => onUpdateDirection(Direction.LONG)}
                  title={t('direction.long')}
                  data-testid="filter-direction-long"
                >
                  <div className={styles.segmentedControlLabel}>{t('direction.long')}</div>
                </li>
              </ul>
            </div>
          </div>
          <div className={styles.gridLeverageFactor} data-testid="filters-grid-leverage-factor">
            <div className={styles.label}>{t('leverageFactor.label-short')}</div>
            <div className={`${styles.filtersSection} ${styles.filtersSectionInput}`}>
              <NumericInput
                placeholder={t('filters.min')}
                value={state.leverageFactorMin}
                min={0}
                max={state.leverageFactorMax || Infinity}
                tabIndex={1}
                onChange={(value) => onUpdateNumericFilters('leverageFactorMin', value)}
              />
              <div className={styles.filtersSeparator}>{t('filters.to-separator')}</div>
              <NumericInput
                placeholder={t('filters.max')}
                value={state.leverageFactorMax}
                min={state.leverageFactorMin || 0}
                max={Infinity}
                tabIndex={2}
                onChange={(value) => onUpdateNumericFilters('leverageFactorMax', value)}
              />
            </div>
          </div>
        </div>
        {!isWebsiteTheme && (
          <div
            className={styles.resetButton}
            onClick={() => onResetFilters()}
            data-testid="reset-button"
          >
            {t('filters.reset')}
          </div>
        )}
        {isWebsiteTheme && (
          <button
            type="button"
            className={styles.websiteResetButton}
            onClick={() => onResetFilters()}
            data-testid="website-reset-button"
          >
            {t('filters.reset-filters')}
          </button>
        )}
      </div>
      {isWebsiteTheme && activeFilters.length > 0 && (
        <div className={styles.appliedFiltersLabel}>{t('filters.applied-filters')}</div>
      )}
      <div
        className={`${styles.activeFilters} ${
          activeFilters.length ? '' : styles.activeFiltersEmpty
        }`}
      >
        {activeFilters &&
          activeFilters.map((activeFilter: ActiveFilter) => (
            <div className={styles.activeFiltersItem} key={activeFilter.id}>
              <div className={styles.activeFiltersLabel} data-testid="active-filters-item">
                {activeFilter.name}
              </div>
              <button
                name={t('filters.remove')}
                title={t('filters.remove')}
                type="button"
                className={styles.activeFiltersItemClose}
                data-testid="active-filters-item-close"
                onClick={() => onRemoveFilter(activeFilter)}
              ></button>
            </div>
          ))}
      </div>

      <SearchResult
        totalResults={totalResults}
        error={error}
        instrumentType={null}
        isLoading={isLoading}
        searchTab={SearchTab.CONSTANT}
        hasFilters={activeFilters.length > 0}
      />
    </>
  );
};

export default ConstantFilters;

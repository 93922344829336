import { useEffect, useMemo, useState } from 'react';
import abortableFetch, { toUrl } from '../utils/fetch';
import { Underlying, UnderlyingListItem } from '../components/Filters';
import { useSessionData } from './use-session-data';
import { mapFiltersData, UnderlyingListItemFromAPI } from '../utils/filters-utils';
import { useWebsiteScreenerParams } from './use-website-screener-params';

export const UNDERLYINGS_URL = '/findergateway/v1/etp/instruments/underlyings';
export const UNDERLYINGS_URL_UNAUTHORIZED = '/api/screener/ftp/v1/etp/instruments/underlyings';

export enum UnderlyingType {
  INDICES = 'INDICES',
  FX = 'FX',
  CRYPTOCURRENCY = 'CRYPTOCURRENCY',
  SHARES = 'EQUITIES',
  COMMODITIES = 'COMMODITIES',
}

const useUnderlyings = (preselectedUnderlying: Underlying[]) => {
  const { allowUnauthorizedAPI, locale } = useWebsiteScreenerParams();
  const { apiHost, cst, currentAccountId } = useSessionData();
  const [underlyings, setUnderlyings] = useState<UnderlyingListItem[]>([]);

  const preselectedUnderlyingIds = useMemo(
    () => preselectedUnderlying.filter((ul) => ul.isChecked).map((u) => u.underlyingId),
    [preselectedUnderlying]
  );

  useEffect(
    () => {
      // Load list of underlyings only once, there is no need to reload it every time.
      if (underlyings.length > 0) {
        return;
      }

      const url = allowUnauthorizedAPI
        ? toUrl(apiHost, UNDERLYINGS_URL_UNAUTHORIZED, { locale: locale.slice(0, 5) })
        : toUrl(apiHost, UNDERLYINGS_URL);

      const [fetch, abort] = allowUnauthorizedAPI
        ? abortableFetch(null, null)
        : abortableFetch(null, cst, currentAccountId);

      (async () => {
        try {
          const response = await fetch(url, { method: 'get' });

          /* istanbul ignore else */
          if (response.ok) {
            const json: UnderlyingListItemFromAPI[] = await response.json();

            // API endpoint has changed therefore received data needs to be mapped.
            const mappedJson: UnderlyingListItem[] = mapFiltersData(json);

            const underlyings = mappedJson.map((item) => {
              /* istanbul ignore else */
              if (item.category === UnderlyingType.SHARES) {
                // Sort items in Shares category
                item.underlyings = item.underlyings.sort((a, b) =>
                  a.underlyingName.localeCompare(b.underlyingName)
                );
              }

              item.underlyings.forEach((ul) => {
                ul.isChecked = preselectedUnderlyingIds.includes(ul.underlyingId);
              });

              return {
                category: item.category,
                categoryName: item.categoryName,
                underlyings: item.underlyings,
              };
            });

            setUnderlyings(underlyings);
          }
        } catch (error) /* istanbul ignore next */ {
          if (error.name === 'AbortError') {
            return;
          }

          setUnderlyings([]);
        }
      })();

      return () => abort();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [apiHost, cst, currentAccountId, preselectedUnderlyingIds, underlyings.length]
  );

  return {
    underlyings,
  };
};

export default useUnderlyings;

import { useState } from 'react';

// hooks inspired by https://usehooks.com/useLocalStorage/

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useSessionStorage = (key: string, initialValue: any) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = sessionStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      /* istanbul ignore next */
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that
  // persists the new value to localStorage.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setValue = (value: any) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      sessionStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      /* istanbul ignore next */
      console.warn(error); // eslint-disable-line no-console
    }
  };
  return [storedValue, setValue];
};

export default useSessionStorage;

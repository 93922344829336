// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._7NCsniZbYfhMEFMFiL8dpg\\=\\= {\n  margin: var(--search-result-margin);\n}\n\n.V\\+yofRacuPSpH6z\\+6XV0Kw\\=\\= {\n  margin: var(--search-result-error-margin);\n}\n\n.d65umrl8F0uw8kj7lZuXMQ\\=\\= {\n  background-color: var(--search-result-banner-message);\n  padding: 3px 8px;\n  width: fit-content;\n  margin-top: var(--search-result-banner-message-margin-top);\n}\n\n.VL-HYB6rD4fNNnNDrJ3eqQ\\=\\= {\n  background-color: var(--search-result-banner-message);\n  padding: 3px 8px;\n  width: fit-content;\n  margin-top: var(--search-result-banner-no-result-margin-top);\n}\n\n.rWS8HSifLUjVkshYZDfRsA\\=\\= {\n  color: var(--text-primary);\n  font-size: 11px;\n  height: 12px;\n  line-height: 12px;\n}\n", "",{"version":3,"sources":["webpack://./src/styles/search-result.module.css"],"names":[],"mappings":"AAAA;EACE,mCAAmC;AACrC;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;EACE,qDAAqD;EACrD,gBAAgB;EAChB,kBAAkB;EAClB,0DAA0D;AAC5D;;AAEA;EACE,qDAAqD;EACrD,gBAAgB;EAChB,kBAAkB;EAClB,4DAA4D;AAC9D;;AAEA;EACE,0BAA0B;EAC1B,eAAe;EACf,YAAY;EACZ,iBAAiB;AACnB","sourcesContent":[".searchResult {\n  margin: var(--search-result-margin);\n}\n\n.searchResultError {\n  margin: var(--search-result-error-margin);\n}\n\n.searchResultBannerMessage {\n  background-color: var(--search-result-banner-message);\n  padding: 3px 8px;\n  width: fit-content;\n  margin-top: var(--search-result-banner-message-margin-top);\n}\n\n.searchResultBannerNoResult {\n  background-color: var(--search-result-banner-message);\n  padding: 3px 8px;\n  width: fit-content;\n  margin-top: var(--search-result-banner-no-result-margin-top);\n}\n\n.searchResultTotalFound {\n  color: var(--text-primary);\n  font-size: 11px;\n  height: 12px;\n  line-height: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchResult": "_7NCsniZbYfhMEFMFiL8dpg==",
	"searchResultError": "V+yofRacuPSpH6z+6XV0Kw==",
	"searchResultBannerMessage": "d65umrl8F0uw8kj7lZuXMQ==",
	"searchResultBannerNoResult": "VL-HYB6rD4fNNnNDrJ3eqQ==",
	"searchResultTotalFound": "rWS8HSifLUjVkshYZDfRsA=="
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
// @ts-ignore
import styles from '../styles/arrow-badge.module.css';

export type ArrowBadgeProps = {
  label: string;
};

const ArrowBadge: React.FC<ArrowBadgeProps> = ({ label }) => {
  return (
    <span className={styles.arrowBadge} data-testid="arrow-badge-new">
      {label}
    </span>
  );
};

export default ArrowBadge;

// Adjust Expiry value to match timezone used in API so the results match
export const adjustExpiryTime = (value: number | undefined) => {
  if (!value) {
    return undefined;
  }

  const timezoneOffset = new Date(value).getTimezoneOffset();

  return new Date(value - timezoneOffset * 60000).getTime();
};

import React, { CSSProperties } from 'react';
import { AutoSizer, InfiniteLoader, List } from 'react-virtualized';
import { Constant, SelectMarketCallback } from './MtfScreener';
import { CONSTANT_ROWS_PER_PAGE } from './ConstantTab';
import ConstantRow from './ConstantRow';
import { useWebsiteScreenerParams } from '../hooks/use-website-screener-params';

export type ConstantListProps = {
  data: Constant[];
  loadNextPage: Function;
  scrollToIndex?: number;
  selectMarket: SelectMarketCallback;
  selectedMarketId: string;
  totalResults: number;
  priceTicksEnabled: boolean;
};

const ConstantList: React.FC<ConstantListProps> = ({
  data,
  loadNextPage,
  scrollToIndex,
  selectMarket,
  selectedMarketId,
  totalResults,
  priceTicksEnabled,
}) => {
  const { rowHeight } = useWebsiteScreenerParams();

  function isRowLoaded({ index }: { index: number }) {
    return !!data[index];
  }

  function rowRenderer({
    key,
    style,
    index,
  }: {
    key: string;
    style: CSSProperties;
    index: number;
  }) {
    const constant: Constant = data[index];

    return (
      constant && (
        <ConstantRow
          key={key}
          style={style}
          constant={constant}
          selectMarket={selectMarket}
          selectedMarketId={selectedMarketId}
          priceTicksEnabled={priceTicksEnabled}
        />
      )
    );
  }

  return (
    <AutoSizer>
      {({ height, width }) => (
        <InfiniteLoader
          isRowLoaded={isRowLoaded}
          loadMoreRows={() => loadNextPage()}
          rowCount={totalResults}
          minimumBatchSize={CONSTANT_ROWS_PER_PAGE}
        >
          {({ onRowsRendered, registerChild }) => (
            <List
              width={width}
              height={height}
              ref={registerChild}
              onRowsRendered={onRowsRendered}
              rowCount={data.length}
              rowHeight={rowHeight}
              rowRenderer={rowRenderer}
              scrollToIndex={scrollToIndex}
            />
          )}
        </InfiniteLoader>
      )}
    </AutoSizer>
  );
};

export default ConstantList;

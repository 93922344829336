import { useCallback, useEffect } from 'react';

/*
 * Call updateFilters on every change so a new fetch is made (with debounce)
 */
export const useDebouncedEffect = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  effect: any,
  delay: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state: any,
  cleanupFunction?: Function
) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const callback = useCallback(effect, [state]);

  useEffect(() => {
    /* istanbul ignore next */
    const handler = setTimeout(() => {
      callback();
    }, delay);
    return () => {
      clearTimeout(handler);

      if (cleanupFunction) {
        cleanupFunction();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callback, delay]);
};

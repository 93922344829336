import React, { useEffect, useMemo, useState } from 'react';
// @ts-ignore
import screenerStyles from '../styles/index.module.css';
// @ts-ignore
import gridHeaderStyles from '../styles/grid-header.module.css';
// @ts-ignore
import gridStyles from '../styles/grid.module.css';
import { SelectMarketCallback, Turbo } from './MtfScreener';
import Filters, { filtersApplied, Underlying, UnderlyingListItem } from './Filters';
import useTurbo, { TurboSortableFields } from '../hooks/use-turbo';
import { useTranslation } from 'react-i18next';
import TurboList from './TurboList';
import { useWebsiteScreenerParams } from '../hooks/use-website-screener-params';

export type TurboMeta = {
  pageNumber: number;
  pageSize: number;
  totalPages: number;
  totalResults: number;
};

export type TurboResponse = {
  meta: TurboMeta;
  results: {
    instruments: Turbo[];
    pageNumber: number;
    pageSize: number;
  };
};

export type TurboTabProps = {
  priceTicksEnabled: boolean;
  selectMarket: SelectMarketCallback;
  selectedMarketId: string;
};

const TurboTab: React.FC<TurboTabProps> = ({
  priceTicksEnabled,
  selectMarket,
  selectedMarketId,
}) => {
  const { t } = useTranslation();
  const { rowHeight } = useWebsiteScreenerParams();

  const [showingScrollbar, setShowingScrollbar] = useState<boolean>(true);

  const {
    data,
    error,
    isLoading,
    totalResults,
    loadNextPage,
    filters,
    updateFilters,
    sorting,
    updateSorting,
    trackFilterInteraction,
  } = useTurbo();

  useEffect(() => {
    const gridBody = document.getElementById('turbo-list-wrapper');

    if (gridBody) {
      const totalRowsHeight = data.length * rowHeight;
      setShowingScrollbar(gridBody.clientHeight < totalRowsHeight);
    }
  }, [data.length, rowHeight]);

  const columnHeaderSortedClass = (column: TurboSortableFields) => {
    if (sorting.sortBy !== column) {
      return gridHeaderStyles.gridHeaderItemSortable;
    }

    return sorting.sortAsc
      ? gridHeaderStyles.gridHeaderItemSortedAsc
      : gridHeaderStyles.gridHeaderItemSortedDesc;
  };

  const sortColumn = (sortBy: TurboSortableFields) => {
    if (sortBy === sorting.sortBy) {
      updateSorting({
        sortBy,
        sortAsc: !sorting.sortAsc,
      });
    } else {
      const defaultDesc = sortBy === TurboSortableFields.leverage;

      updateSorting({
        sortBy,
        sortAsc: !defaultDesc,
      });
    }
  };

  const preselectedUnderlyings = useMemo(
    () =>
      filters.underlyings.reduce(
        (total: Underlying[], current: UnderlyingListItem) => total.concat(current.underlyings),
        []
      ),
    [filters.underlyings]
  );

  return (
    <section className={screenerStyles.mtfScreener} data-testid="turbo-tab-section">
      <Filters
        filters={filters}
        updateFilters={updateFilters}
        trackFilterInteraction={trackFilterInteraction}
        preselectedUnderlyings={preselectedUnderlyings}
        totalResults={totalResults}
        error={error}
        isLoading={isLoading}
      />
      <div className={`${gridStyles.gridWrapper} ${gridStyles.gridWrapperScrollable}`} data-testid="turbo-grid-results">
        {isLoading && (
          <div data-testid="turbo-grid-loading" className={gridStyles.gridLoading}></div>
        )}
        {!isLoading && error && (
          <p className={screenerStyles.searchPrompt} data-testid="no-records-message">
            {t('error-generic')}
          </p>
        )}

        {!error && !(totalResults === 0 && filtersApplied(filters)) && (
          <>
            <div
              className={`${gridHeaderStyles.gridHeader} ${
                showingScrollbar ? gridHeaderStyles.gridHeaderWithScrollbar : ''
              }`}
            >
              <div
                className={`${gridHeaderStyles.gridHeaderItem} ${
                  gridHeaderStyles.gridTurboHeaderMarket
                } ${columnHeaderSortedClass(TurboSortableFields.underlyingName)}`}
                onClick={() => sortColumn(TurboSortableFields.underlyingName)}
                title={t('underlying.label-long')}
                data-testid="instrumentNameHeader"
              >
                {t('underlying.label-short')}
              </div>
              <div
                className={`${gridHeaderStyles.gridHeaderItem} ${
                  gridHeaderStyles.gridHeaderDirection
                } ${columnHeaderSortedClass(TurboSortableFields.optionType)}`}
                onClick={() => sortColumn(TurboSortableFields.optionType)}
                title={t('direction.label-long')}
                data-testid="directionHeader"
              >
                {t('direction.label-short')}
              </div>
              <div
                className={`${gridHeaderStyles.gridHeaderItem}  ${
                  gridHeaderStyles.gridHeaderKOLevel
                } ${columnHeaderSortedClass(TurboSortableFields.strikeLevel)}`}
                onClick={() => sortColumn(TurboSortableFields.strikeLevel)}
                title={t('ko-level.label-long')}
                data-testid="koLevelHeader"
              >
                {t('ko-level.label-short')}
              </div>
              <div
                className={`${gridHeaderStyles.gridHeaderItem} ${
                  gridHeaderStyles.gridHeaderUnderlyingLevel
                } ${columnHeaderSortedClass(TurboSortableFields.underlyingLevel)}`}
                onClick={() => sortColumn(TurboSortableFields.underlyingLevel)}
                title={t('underlying-level.label-long')}
                data-testid="ulLevelHeader"
              >
                {t('underlying-level.label-short')}
              </div>
              <div
                className={`${gridHeaderStyles.gridHeaderItem} ${
                  gridHeaderStyles.gridHeaderDistanceToKO
                } ${columnHeaderSortedClass(TurboSortableFields.distance)}`}
                onClick={() => sortColumn(TurboSortableFields.distance)}
                title={t('distance-to-ko.label-long')}
                data-testid="distanceToKoHeader"
              >
                {t('distance-to-ko.label-short')}
              </div>
              <div
                className={`${gridHeaderStyles.gridHeaderItem} ${
                  gridHeaderStyles.gridHeaderLeverage
                } ${columnHeaderSortedClass(TurboSortableFields.leverage)}`}
                onClick={() => sortColumn(TurboSortableFields.leverage)}
                title={t('leverage.label-long')}
                data-testid="leverageHeader"
              >
                {t('leverage.label-short')}
              </div>
              <div
                className={`${gridHeaderStyles.gridHeaderItem} ${gridHeaderStyles.gridHeaderSell} ${
                  priceTicksEnabled ? gridHeaderStyles.gridHeaderSellBuyWithPriceTicks : ''
                }`}
                title={t('list-header.sell')}
                data-testid="sellHeader"
              >
                {t('list-header.sell')}
              </div>
              <div
                className={`${gridHeaderStyles.gridHeaderItem} ${gridHeaderStyles.gridHeaderBuy} ${
                  priceTicksEnabled ? gridHeaderStyles.gridHeaderSellBuyWithPriceTicks : ''
                }`}
                title={t('list-header.buy')}
                data-testid="buyHeader"
              >
                {t('list-header.buy')}
              </div>
            </div>

            <ul id="turbo-list-wrapper" className={gridStyles.gridAdvancedSearch}>
              {!isLoading && data && (
                <TurboList
                  data={data}
                  loadNextPage={loadNextPage}
                  selectMarket={selectMarket}
                  selectedMarketId={selectedMarketId}
                  totalResults={totalResults}
                  priceTicksEnabled={priceTicksEnabled}
                />
              )}
            </ul>
          </>
        )}
      </div>
    </section>
  );
};

export default TurboTab;

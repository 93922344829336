import {
  ConstantFiltersState,
  ConstantSortingState,
  Direction,
  Underlying,
  UnderlyingListItem,
} from '../components/ConstantFilters';
import {
  Constant,
  INSTRUMENTS_URL,
  INSTRUMENTS_URL_UNAUTHORIZED,
  InstrumentType,
} from '../components/MtfScreener';
import { ConstantSortableFields, ConstantURLParams } from './use-constant';
import { toUrl } from '../utils/fetch';
import { CONSTANT_ROWS_PER_PAGE, ConstantTabState } from '../components/ConstantTab';
import { getConstantURLParams } from '../utils/url-utils';

export type Action =
  | {
      type: 'LOADING_COMPLETE';
      instruments: Constant[];
      pageNumber: number;
      totalPages: number;
      totalResults: number;
    }
  | {
      type: 'LOADING_STARTED';
    }
  | { type: 'LOAD_NEXT_PAGE' }
  | { type: 'ERROR'; error: Error | string | null }
  | { type: 'SET_FILTERS'; filters: ConstantFiltersState }
  | { type: 'SET_SORTING'; sorting: ConstantSortingState };

const constantReducer = (state: ConstantTabState, action: Action): ConstantTabState => {
  switch (action.type) {
    case 'LOADING_STARTED': {
      const { pageNumber } = state;
      return {
        ...state,
        data: pageNumber === 0 ? [] : /* istanbul ignore next */ state.data,
        isLoading: pageNumber === 0,
        totalPages: pageNumber === 0 ? 0 : /* istanbul ignore next */ state.totalPages,
        totalResults: pageNumber === 0 ? 0 : /* istanbul ignore next */ state.totalResults,
      };
    }
    case 'LOADING_COMPLETE':
      const { pageNumber, totalPages, instruments } = action;
      const startIndex = pageNumber * CONSTANT_ROWS_PER_PAGE;

      state.data.splice(startIndex, instruments.length, ...instruments);

      // Bug fix for an existing MiddleWare issue where
      // the totalResults keep changing when a new page
      // is delivered causing a surplus of buffer rows.
      if (pageNumber + 1 >= totalPages) {
        state.data = state.data.filter((item) => {
          return !item.isLoading;
        });
      }

      return {
        ...state,
        isLoading: false,
        data: state.data,
        error: null,
        totalPages: action.totalPages,
        totalResults: action.totalResults,
      };

    case 'LOAD_NEXT_PAGE':
      /* istanbul ignore next */
      if (state.pageNumber >= state.totalPages - 1) {
        return state;
      }

      /* istanbul ignore next */
      return {
        ...state,
        data: getDataWithLoadingRows(state.data),
        pageNumber: state.pageNumber + 1,
        url: generateUrl(
          state.allowUnauthorizedAPI,
          state.locale,
          state.apiHost,
          state.pageNumber + 1,
          state.filters,
          state.sorting
        ),
      };

    case 'ERROR':
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };

    case 'SET_SORTING':
      return {
        ...state,
        sorting: action.sorting,
        pageNumber: 0,
        url: generateUrl(
          state.allowUnauthorizedAPI,
          state.locale,
          state.apiHost,
          0,
          state.filters,
          action.sorting
        ),
      };

    case 'SET_FILTERS':
      return {
        ...state,
        // Deep clone of the filters
        filters: JSON.parse(JSON.stringify(action.filters)),
        pageNumber: 0,
        url: generateUrl(
          state.allowUnauthorizedAPI,
          state.locale,
          state.apiHost,
          0,
          action.filters,
          state.sorting
        ),
      };

    /* istanbul ignore next */
    default:
      return state;
  }
};

export function generateUrl(
  allowUnauthorizedAPI: boolean,
  locale: string,
  apiHost: string,
  pageNumber: number,
  filters: ConstantFiltersState,
  sorting: ConstantSortingState
) {
  const urlParams: ConstantURLParams = getConstantURLParams(CONSTANT_ROWS_PER_PAGE, pageNumber);

  // Add filters from selected underlyings
  urlParams.underlyingId = filters.underlyings
    .reduce(
      (total: Underlying[], current: UnderlyingListItem) => total.concat(current.underlyings),
      []
    )
    .filter((ul) => ul.isChecked)
    .map((ul) => ul.underlyingId);

  // Add filters on direction
  if (filters.direction !== Direction.ALL) {
    urlParams.optionType = filters.direction === Direction.SHORT ? 'PUT' : 'CALL';
  }

  [
    {
      key: 'leverageFactor',
      min: filters.leverageFactorMin,
      max: filters.leverageFactorMax,
    },
  ].forEach(({ key, min, max }) => {
    const params = [];
    const haveMin = min !== undefined || min === 0;
    const haveMax = max !== undefined || max === 0;
    if (haveMax && !haveMin) {
      params.push(`lte:${max}`);
    } else if (haveMin && !haveMax) {
      params.push(`gte:${min}`);
    } else if (haveMin && haveMax) {
      min = Number(min);
      max = Number(max);
      if (min === max || min < max) {
        params.push(`gte:${min}`);
        params.push(`lte:${max}`);
      } else {
        // We are following a 'forgiving' approach
        // to allow the wrong order to be inputted
        // and continue when searching from Y to X
        params.push(`gte:${max}`);
        params.push(`lte:${min}`);
      }
    }
    switch (key) {
      case 'leverageFactor':
        urlParams.leverageFactor = params;
        break;
    }
    urlParams.sort = `${sorting.sortAsc ? '+' : '-'}${
      ConstantSortableFields[sorting.sortBy]
      // 'underlyingName'
    },-underlyingPopularity,+underlyingName`;
  });

  const path = allowUnauthorizedAPI ? INSTRUMENTS_URL_UNAUTHORIZED : INSTRUMENTS_URL;

  if (allowUnauthorizedAPI) {
    urlParams.locale = locale.slice(0, 5);
  }

  return toUrl(apiHost, path, urlParams);
}

/* istanbul ignore next */

function getDataWithLoadingRows(data: Constant[]) {
  /* istanbul ignore next */
  if (data.find((constant) => constant.isLoading)) {
    return data;
  } else {
    const dataWithLoadingRows = [...data];

    for (let i = 0; i < CONSTANT_ROWS_PER_PAGE; i++) {
      dataWithLoadingRows.push({
        id: i.toString(),
        isLoading: true,
        bid: 0,
        distance: 0,
        epic: '',
        instrumentType: InstrumentType.CONSTANT,
        leverage: 0,
        leverageFactor: 0,
        marketName: '',
        name: '',
        offer: 0,
        optionType: '',
        underlyingName: '',
        underlyingLevel: 0,
      });
    }
    return dataWithLoadingRows;
  }
}

export default constantReducer;

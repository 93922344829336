const ENV_URLS = {
  test: {
    apiHost: 'https://net-deal.ig.com',
    lightstreamerOrigin: 'https://net-wtp.ig.com',
    lightstreamerEnv: 'net',
  },
  uat: {
    apiHost: 'https://web-deal.ig.com',
    lightstreamerOrigin: 'https://web-wtp.ig.com',
    lightstreamerEnv: 'web',
  },
  demo :{
    apiHost: 'https://demo-deal.ig.com',
    lightstreamerOrigin: 'https://demo-wtp.ig.com',
    lightstreamerEnv: 'demo',
  },
  prod: {
    apiHost: 'https://deal.ig.com',
    lightstreamerOrigin: 'https://deal.wtp.com',
    lightstreamerEnv: 'prod',
  },
  aws_test: {
    apiHost: 'https://net-deal.ig.com',
    lightstreamerOrigin: 'https://net-upp.ig.com',
    lightstreamerEnv: 'net',
  },
  aws_uat: {
    apiHost: 'https://web-deal.ig.com',
    lightstreamerOrigin: 'https://web-upp.ig.com',
    lightstreamerEnv: 'web',
  },
  aws_demo: {
    apiHost: 'https://demo-deal.ig.com',
    lightstreamerOrigin: 'https://demo-upp.ig.com',
    lightstreamerEnv: 'demo',
  },
  aws_prod: {
    apiHost: 'https://deal.ig.com',
    lightstreamerOrigin: 'https://upp.ig.com',
    lightstreamerEnv: 'prod',
  },
};

export const getEnvURLs = (currentEnvironment: string, allowUnauthorizedAPI: boolean) => {
  const dataKey = allowUnauthorizedAPI
    ? `aws_${currentEnvironment.toLowerCase()}`
    : currentEnvironment.toLowerCase();

  if (!Object.keys(ENV_URLS).includes(dataKey)) {
    return {
      apiHost: '',
      lightstreamerOrigin: '',
      lightstreamerEnv: '',
    };
  }

  return ENV_URLS[dataKey];
};

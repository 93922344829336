import React, { createContext, useContext } from 'react';

type IActionsContext = {
  actions: { [key: string]: Function };
};

type ActionsProps = {
  children: React.ReactNode;
  actions: { [key: string]: Function };
};

export const ActionsContext = createContext<IActionsContext>({
  actions: {},
});

export const ActionsProvider: React.FC<ActionsProps> = ({ children, actions }) => (
  <ActionsContext.Provider
    value={{
      actions,
    }}
  >
    {children}
  </ActionsContext.Provider>
);

export const useActions = () => useContext(ActionsContext);

import React, { RefObject, useRef, useState } from 'react';
// @ts-ignore
import styles from '../styles/multi-select-dropdown.module.css';
import useClickOutside from 'click-outside';
import { Underlying } from './Filters';
import { useTranslation } from 'react-i18next';

export type MultiSelectDropdownProps = {
  items: Underlying[];
  onToggleItem: Function;
  placeholder: string;
};

const MultiSelectDropdown: React.FC<MultiSelectDropdownProps> = ({
  items,
  onToggleItem,
  placeholder,
}) => {
  const { t } = useTranslation();

  const dropdownRef: RefObject<HTMLDivElement> = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = () => {
    /* istanbul ignore else */
    if (isOpen) {
      setIsOpen(false);
    }
  };

  useClickOutside(dropdownRef, handleClickOutside);

  const list =
    !items || items.length === 0 ? (
      <li className="filters-multiselect_none" data-testid="filters-empty-multiselect">
        {t('underlying.none-available')}
      </li>
    ) : (
      items.map((item: Underlying) => (
        <li key={item.underlyingId}>
          <label className={styles.multiSelectItemsLabel} htmlFor={item.underlyingId}>
            <input
              id={item.underlyingId}
              type="checkbox"
              className={styles.multiSelectItemCheckbox}
              onChange={() => onToggleItem(item.underlyingId, item.isChecked)}
              checked={item.isChecked}
              data-testid={item.underlyingId}
            />
            {item.underlyingName}
          </label>
        </li>
      ))
    );

  return (
    <div className={styles.multiSelectContainer} ref={dropdownRef}>
      <div
        className={styles.multiSelectInput}
        onClick={toggleDropdown}
        data-testid={`filter-multiselect-${placeholder}`}
      >
        <span className={styles.multiSelectPlaceholder}>{placeholder}</span>
      </div>
      {isOpen && (
        <div
          className={styles.multiselectDropdown}
          data-testid={`filter-multiselect-dropdown-${placeholder}`}
        >
          <ul className={styles.multiSelectItems}>{list}</ul>
        </div>
      )}
    </div>
  );
};

export default MultiSelectDropdown;

import React, { CSSProperties, useEffect, useMemo, useState } from 'react';
// @ts-ignore
import gridStyles from '../styles/grid.module.css';
import { Constant, InstrumentType, SelectMarketCallback } from './MtfScreener';
import { useTranslation } from 'react-i18next';
import { presentNumber } from '../utils/rounding-utils';
import { useLsSubscription } from '@ig-wpx/lightstreamer';
import PriceTick from './PriceTick';
import { useSessionData } from '../hooks/use-session-data';
import { useWebsiteScreenerParams } from '../hooks/use-website-screener-params';

export type ConstantRowProps = {
  selectMarket: SelectMarketCallback;
  selectedMarketId: string;
  style: CSSProperties;
  constant: Constant;
  priceTicksEnabled: boolean;
};

const ConstantRow: React.FC<ConstantRowProps> = ({
  constant,
  style,
  selectMarket,
  selectedMarketId,
  priceTicksEnabled,
}) => {
  const { t } = useTranslation();
  const { currentAccountId } = useSessionData();
  const { allowUnauthorizedAPI } = useWebsiteScreenerParams();

  const [bid, setBid] = useState<number>();
  const [offer, setOffer] = useState<number>();
  const [marketAvailability, setMarketAvailability] = useState<boolean>(true);

  const NO_VALUE = '-';
  const FIELDS = ['bid', 'offer', 'market_status'];
  const SUBSCRIPTION_URL = allowUnauthorizedAPI
    ? `V2-F-BID,OFR|${constant.epic}`
    : `MARKET_V4:${currentAccountId}:${constant.epic}`;

  const subscriptionItems = useMemo(() => [SUBSCRIPTION_URL], [SUBSCRIPTION_URL]);

  const { data } = useLsSubscription(subscriptionItems, FIELDS, 'MERGE');

  useEffect(() => {
    if (!data) {
      return;
    }

    /* istanbul ignore else */
    if (data.bid) {
      setBid(parseFloat(data.bid));
    }

    /* istanbul ignore else */
    if (data.offer) {
      setOffer(parseFloat(data.offer));
    }

    const isMarketAvailable = data.market_status !== 'UNAVAILABLE';

    setMarketAvailability(isMarketAvailable);
  }, [data]);

  const showCellData = (cellData: undefined | string | number) => {
    return cellData ? cellData.toString() : NO_VALUE;
  };

  const showCellNumberData = (cellData: undefined | number) => {
    return cellData === undefined || isNaN(cellData) ? NO_VALUE : presentNumber(cellData);
  };

  const showCellNumberDataWithTicks = (cellData: undefined | number) => {
    return cellData === undefined || isNaN(cellData) ? (
      <span className={gridStyles.noPriceTickPlaceholder}>{NO_VALUE}</span>
    ) : (
      <PriceTick price={+presentNumber(cellData)} />
    );
  };

  const showLeverageFactor = (cellData: undefined | number) => {
    return cellData === undefined || isNaN(cellData) ? NO_VALUE : `${cellData.toFixed(0)}x`;
  };

  const showDirection = (optionType: undefined | string) => {
    return optionType ? t(`direction.${optionType.toLowerCase()}`) : NO_VALUE;
  };

  const loadedRow = (
    <li
      className={`${gridStyles.gridRow} ${
        constant.id === selectedMarketId ? gridStyles.gridRowSelected : ''
      } ${!marketAvailability ? gridStyles.gridRowDisabled : ''}`}
      data-testid="constant-list-row"
      style={style}
      onClick={() => {
        /* istanbul ignore else */
        if (marketAvailability) {
          selectMarket({ ...constant, instrumentType: InstrumentType.CONSTANT });
        }
      }}
    >
      <div
        className={`${gridStyles.gridRowItem} ${gridStyles.gridConstantRowMarket}`}
        title={showCellData(constant.underlyingName)}
        data-testid="underlying-name"
      >
        {showCellData(constant.underlyingName)}
      </div>
      <div
        className={`${gridStyles.gridRowItem} ${gridStyles.gridRowDirection}`}
        title={showDirection(constant.optionType)}
        data-testid="option-type"
      >
        {showDirection(constant.optionType)}
      </div>
      <div
        className={`${gridStyles.gridRowItem} ${gridStyles.gridRowLeverageFactor}`}
        data-testid="leverage-factor"
        title={showLeverageFactor(constant.leverageFactor)}
      >
        {showLeverageFactor(constant.leverageFactor)}
      </div>
      <div
        className={`${gridStyles.gridRowItem} ${gridStyles.gridRowConstantSell}`}
        title={showCellNumberData(bid)}
        data-testid="bid"
      >
        {priceTicksEnabled ? showCellNumberDataWithTicks(bid) : showCellNumberData(bid)}
      </div>
      <div
        className={`${gridStyles.gridRowItem} ${gridStyles.gridRowBuy}`}
        title={showCellNumberData(offer)}
        data-testid="offer"
      >
        {priceTicksEnabled ? showCellNumberDataWithTicks(offer) : showCellNumberData(offer)}
      </div>
    </li>
  );

  const loadingRow = (
    <li
      className={`${gridStyles.gridRow} ${gridStyles.gridRowLoading}`}
      data-testid="row-loading"
      style={style}
    >
      <div className={gridStyles.gridRowLoadingAnimation}></div>
    </li>
  );

  return constant.isLoading ? loadingRow : loadedRow;
};

export default ConstantRow;

import MtfScreener from './components/MtfScreener';
import { useEffect, useState } from 'react';
import initialiseI18n from './i18n';
import { ActionsProvider } from './hooks/use-actions';
import { LightstreamerProvider } from '@ig-wpx/lightstreamer';
import { SessionDataProvider } from './hooks/use-session-data';
import { THEME_CLASSES, WebsiteScreenerParamsProvider } from './hooks/use-website-screener-params';
import { getEnvURLs } from './utils/env-utils';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { getLocaleForI18n } from './utils/i18n-utils';

type RegisteredLocale = {
  [key: string]: Locale;
};

export const REGISTERED_LOCALES: RegisteredLocale = {};

export default function Root(props) {
  const [translationsLoaded, setTranslationsLoaded] = useState(false);
  const [selectedMarketId, setSelectedMarketId] = useState(null);
  const [selectedUnderlyingMarketId, setSelectedUnderlyingMarketId] = useState(null);

  const actions = {
    onSearch: props.onSearch,
    onSearchInteraction: props.onSearchInteraction,
    onSort: props.onSort,
    onFilter: props.onFilter,
  };

  const currentLocale = props.screenerPreferences?.preferences?.currentLocale;
  const currentLocaleCode = currentLocale === 'en' ? 'en_GB' : currentLocale;

  useEffect(() => {
    initialiseI18n(getLocaleForI18n(currentLocaleCode)).then(() => {
      setTranslationsLoaded(true);
      setDatePickerLocale(currentLocaleCode);
    });
  }, [currentLocaleCode]);

  useEffect(() => {
    setSelectedMarketId(props.selectedMarketId);
  }, [props.selectedMarketId]);

  useEffect(() => {
    setSelectedUnderlyingMarketId(props.selectedUnderlyingMarketId);
  }, [props.selectedUnderlyingMarketId]);

  if (!translationsLoaded) {
    return null;
  }

  const markMTFAdvancedSearchAsSeen =
    props.screenerPreferences?.setters?.markMTFAdvancedSearchAsSeen ||
    function () {
      // eslint-disable-next-line no-console
      console.log('Call markMTFAdvancedSearchAsSeen');
    };

  const selectMarket =
    props.selectMarket ||
    function (market) {
      // eslint-disable-next-line no-console
      console.log('Call selectMarket', market);
    };

  const selectUnderlyingMarket =
    props.selectUnderlyingMarket ||
    function (market) {
      // eslint-disable-next-line no-console
      console.log('Call selectUnderlyingMarket', market);
    };

  const setIsinInfoBoxAsDismissed =
    props.screenerPreferences?.setters?.setIsinInfoBoxAsDismissed ||
    function () {
      // eslint-disable-next-line no-console
      console.log('Call setIsinInfoBoxAsDismissed');
    };

  const currentAccountId = !props.sessionData ? null : props.sessionData.currentAccountId;
  const themeProp = props.screenerPreferences?.preferences?.currentTheme;
  const theme = !THEME_CLASSES[themeProp] ? THEME_CLASSES.DEFAULT : THEME_CLASSES[themeProp];
  const envURLs = getEnvURLs(
    props.currentEnvironment,
    props.screenerPreferences?.preferences?.allowUnauthorizedAPI
  );

  return (
    <SessionDataProvider
      currentAccountId={currentAccountId}
      apiHost={envURLs.apiHost}
      xst={props.xst}
      cst={props.cst}
    >
      <LightstreamerProvider
        origin={envURLs.lightstreamerOrigin}
        client={{
          currentAccountId: currentAccountId,
          cst: props.cst,
          env: envURLs.lightstreamerEnv,
        }}
      >
        <ActionsProvider actions={actions}>
          <WebsiteScreenerParamsProvider
            theme={theme}
            locale={currentLocaleCode}
            allowUnauthorizedAPI={props.screenerPreferences?.preferences?.allowUnauthorizedAPI}
          >
            <MtfScreener
              isIsinInfoBoxDismissed={
                props.screenerPreferences?.preferences?.isIsinInfoBoxDismissed
              }
              markMTFAdvancedSearchAsSeen={markMTFAdvancedSearchAsSeen}
              showMTFAdvancedSearchAsNewFeature={
                props.screenerPreferences?.preferences?.showMTFAdvancedSearchAsNewFeature
              }
              showWarrants={props.screenerPreferences?.preferences?.showWarrants}
              showConstants={props.screenerPreferences?.preferences?.showConstants}
              selectedMarketId={selectedMarketId}
              selectMarket={selectMarket}
              selectedUnderlyingMarketId={selectedUnderlyingMarketId}
              selectUnderlyingMarket={selectUnderlyingMarket}
              setIsinInfoBoxAsDismissed={setIsinInfoBoxAsDismissed}
              priceTicksEnabled={props.screenerPreferences?.preferences?.priceTicksEnabled}
              utcOffset={props.screenerPreferences?.preferences?.utcOffset}
            />
          </WebsiteScreenerParamsProvider>
        </ActionsProvider>
      </LightstreamerProvider>
    </SessionDataProvider>
  );
}

async function setDatePickerLocale(locale: string) {
  // This locale is used only for DatePicker and filter badges/pills with date so there
  // should be no difference between different EN locales and default "en-GB" should be fine.
  const localeCode = locale.startsWith('en') ? 'en-GB' : locale.slice(0, 2);
  const localeImportCode = localeCode === 'no' ? 'nb' : localeCode;

  const dateFnLocale = await import(
    /* webpackInclude: /[/](de|en-GB|es|fr|it|nl|nb|sv)\/index\.js/ */
    `date-fns/locale/${localeImportCode}/index.js`
  );

  registerLocale(localeCode, dateFnLocale);
  setDefaultLocale(localeCode);
  REGISTERED_LOCALES[localeCode] = dateFnLocale;
}

import React, { useEffect } from 'react';
// @ts-ignore
import styles from '../styles/index.module.css';
import IsinTab from './IsinTab';
import UnderlyingTab from './UnderlyingTab';
import Tabs, { SearchTab } from './Tabs';
import AdvancedSearchTab, { AdvancedSearchNavigation } from './AdvancedSearchTab';
import useSessionStorage from '../hooks/use-session-storage';
import { UnderlyingTabConfig } from '../hooks/use-underlying-market';
import { FiltersState, SortingState } from './Filters';
import { useWebsiteScreenerParams } from '../hooks/use-website-screener-params';

export type MarkMTFAdvancedSearchAsSeenCallback = () => void;
export type SelectMarketCallback = (instrument: Instrument) => void;
export type SetIsinInfoBoxAsDismissedCallback = () => void;
export type SelectUnderlyingMarketCallback = (market: UnderlyingMarket | null) => void;
export type SaveUnderlyingTabCallback = (s: UnderlyingTabConfig) => void;
export type TrackFilterInteractionCallback = (
  interactionType: string,
  interactionValue: string | undefined,
  instrumentName: string | undefined
) => void;

export type UnderlyingMarket = {
  epic: string;
  referenceEpic: string;
  underlyingName: string;
  underlyingId: string;
  referenceMarketSnapshotData: {
    bidFormatted: string;
    midFormatted: string;
    netChange: string;
    offerFormatted: string;
    percentageChange: string;
  };
};

const ACTIVE_ADVANCED_SEARCH_TAB_STORAGE_KEY = 'mtf-search-advanced-search-active-tab';

export const INSTRUMENTS_URL = '/findergateway/v2/instruments';
export const INSTRUMENTS_URL_UNAUTHORIZED = '/api/screener/ftp/v1/etp/instruments';
export const TURBO_ROWS_PER_PAGE = 100;
export const SELECTED_TAB_STORAGE_KEY = 'mtf-search-selected-tab';

export type AppState = {
  apiHost: string;
  isLoading: boolean;
  data: Turbo[];
  pageNumber: number;
  totalResults: number;
  totalPages: number;
  error: Error | string | null;
  filters: FiltersState;
  sorting: SortingState;
  url: string;
  allowUnauthorizedAPI: boolean;
  locale: string;
};

export type Instrument = {
  id: string;
  bid: number;
  distance: number;
  epic: string;
  instrumentType: InstrumentType;
  isLoading?: boolean;
  leverage: number;
  marketName: string;
  name: string;
  offer: number;
  optionType: string;
  underlyingName: string;
  underlyingLevel: number;
};

export type Constant = Instrument & {
  leverageFactor: number;
};

export type Turbo = Instrument & {
  strikeLevel: number;
};

export type Warrant = Instrument & {
  expiry: number | undefined;
  strikeLevel: number;
};

export enum InstrumentType {
  TURBO = 'TURBO_24',
  CONSTANT = 'CONSTANT_LEVERAGE',
  WARRANT = 'WARRANT',
}

export type InstrumentMeta = {
  pageNumber: number;
  pageSize: number;
  totalPages: number;
  totalResults: number;
};

export type InstrumentsResponse = {
  meta: InstrumentMeta;
  results: {
    instruments: Constant[] | Turbo[];
    pageNumber: number;
    pageSize: number;
  };
};

export type MtfScreenerProps = {
  isIsinInfoBoxDismissed: boolean;
  markMTFAdvancedSearchAsSeen: MarkMTFAdvancedSearchAsSeenCallback;
  priceTicksEnabled: boolean;
  selectMarket: SelectMarketCallback;
  selectedMarketId: string;
  selectUnderlyingMarket: SelectUnderlyingMarketCallback;
  selectedUnderlyingMarketId: string;
  setIsinInfoBoxAsDismissed: SetIsinInfoBoxAsDismissedCallback;
  showMTFAdvancedSearchAsNewFeature: boolean;
  showWarrants: boolean;
  showConstants: boolean;
  utcOffset: number;
};

const MtfScreener: React.FC<MtfScreenerProps> = ({
  isIsinInfoBoxDismissed,
  markMTFAdvancedSearchAsSeen,
  priceTicksEnabled,
  selectMarket,
  selectedMarketId,
  selectUnderlyingMarket,
  selectedUnderlyingMarketId,
  setIsinInfoBoxAsDismissed,
  showMTFAdvancedSearchAsNewFeature,
  showWarrants,
  showConstants,
  utcOffset,
}) => {
  const { currentTheme } = useWebsiteScreenerParams();
  const { allowUnauthorizedAPI } = useWebsiteScreenerParams();
  const [activeTab, setActiveTab] = useSessionStorage(
    SELECTED_TAB_STORAGE_KEY,
    allowUnauthorizedAPI ? SearchTab.ADVANCED_SEARCH : SearchTab.UNDERLYING
  );
  const [activeAdvancedSearchTab, setActiveAdvancedSearchTab] = useSessionStorage(
    ACTIVE_ADVANCED_SEARCH_TAB_STORAGE_KEY,
    AdvancedSearchNavigation.TURBO
  );

  const changeTab = (tab: SearchTab) => {
    setActiveTab(tab);
    selectMarket(null);
    selectUnderlyingMarket(null);
  };

  useEffect(() => {
    if (activeTab === SearchTab.WARRANT && !showWarrants) {
      setActiveTab(SearchTab.UNDERLYING);
    }

    if (activeTab === SearchTab.CONSTANT && !showConstants) {
      setActiveTab(SearchTab.UNDERLYING);
    }
  }, [activeTab, showWarrants, showConstants, setActiveTab]);

  return (
    <section
      className={`${styles.mtfScreener} ig-website-screener`}
      data-testid="website-screener-theme"
      data-website-screener-theme={currentTheme}
    >
      <Tabs
        activeTab={activeTab}
        setActiveTab={changeTab}
        showMTFAdvancedSearchAsNewFeature={showMTFAdvancedSearchAsNewFeature}
        markMTFAdvancedSearchAsSeen={markMTFAdvancedSearchAsSeen}
      />
      {activeTab === SearchTab.UNDERLYING && (
        <UnderlyingTab
          priceTicksEnabled={priceTicksEnabled}
          selectUnderlyingMarket={selectUnderlyingMarket}
          selectedUnderlyingMarketId={selectedUnderlyingMarketId}
          setActiveTab={setActiveTab}
          markMTFAdvancedSearchAsSeen={markMTFAdvancedSearchAsSeen}
        />
      )}
      {activeTab === SearchTab.ISIN && (
        <IsinTab
          isIsinInfoBoxDismissed={isIsinInfoBoxDismissed}
          priceTicksEnabled={priceTicksEnabled}
          selectMarket={selectMarket}
          selectedMarketId={selectedMarketId}
          setIsinInfoBoxAsDismissed={setIsinInfoBoxAsDismissed}
          utcOffset={utcOffset}
          setActiveTab={setActiveTab}
          markMTFAdvancedSearchAsSeen={markMTFAdvancedSearchAsSeen}
        />
      )}
      {activeTab === SearchTab.ADVANCED_SEARCH && (
        <AdvancedSearchTab
          priceTicksEnabled={priceTicksEnabled}
          selectMarket={selectMarket}
          selectedMarketId={selectedMarketId}
          showWarrants={showWarrants}
          showConstants={showConstants}
          utcOffset={utcOffset}
          activeAdvancedSearchTab={activeAdvancedSearchTab}
          setActiveAdvancedSearchTab={setActiveAdvancedSearchTab}
        />
      )}
    </section>
  );
};

export default MtfScreener;

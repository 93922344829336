import React, { useEffect, useRef, useState } from 'react';
// @ts-ignore
import styles from '../styles/underlying-tab.module.css';
// @ts-ignore
import gridHeaderStyles from '../styles/grid-header.module.css';
// @ts-ignore
import gridStyles from '../styles/grid.module.css';
// @ts-ignore
import screenerStyles from '../styles/index.module.css';
import { SearchTab, SetActiveTabCallback } from './Tabs';
import {
  MarkMTFAdvancedSearchAsSeenCallback,
  SelectUnderlyingMarketCallback,
  UnderlyingMarket,
} from './MtfScreener';
import { Trans, useTranslation } from 'react-i18next';
import useSessionStorage from '../hooks/use-session-storage';
import useUnderlyingMarket, {
  DEFAULT_UNDERLYING_TAB_CONFIG,
  UNDERLYING_TAB_LOCAL_STORAGE_KEY,
} from '../hooks/use-underlying-market';
import { useDebouncedEffect } from '../utils/debounce-utils';
import SearchResult from './SearchResult';
import UnderlyingMarketList from './UnderlyingMarketList';
import SingleSelectDropdown from './SingleSelectDropdown';
import { useWebsiteScreenerParams } from '../hooks/use-website-screener-params';

export enum UnderlyingSort {
  popularity = '-underlyingPopularity,underlyingName',
  asc = '+underlyingName',
  desc = '-underlyingName',
}

export const SEARCH_UPDATE_DELAY = 1000;

export type SearchUnderlyingProps = {
  priceTicksEnabled: boolean;
  selectUnderlyingMarket: SelectUnderlyingMarketCallback;
  selectedUnderlyingMarketId: string;
  setActiveTab: SetActiveTabCallback;
  markMTFAdvancedSearchAsSeen: MarkMTFAdvancedSearchAsSeenCallback;
};

export type SearchUnderlyingState = {
  isLoading: boolean;
  pageNumber: number;
  totalResults: number;
  totalPages: number;
  error: Error | string | null;
  underlyingSearch: string;
  data: UnderlyingMarket[];
  sortPreference: UnderlyingSort;
};

const UnderlyingTab: React.FC<SearchUnderlyingProps> = ({
  priceTicksEnabled,
  selectUnderlyingMarket,
  selectedUnderlyingMarketId,
  setActiveTab,
  markMTFAdvancedSearchAsSeen,
}) => {
  const { t } = useTranslation();
  const { isWebsiteTheme, rowHeight } = useWebsiteScreenerParams();

  const searchInputRef = useRef<HTMLInputElement>(null);
  const [showingScrollbar, setShowingScrollbar] = useState<boolean>(true);

  const [initialUnderlyingTabConfig, saveUnderlyingTabConfig] = useSessionStorage(
    UNDERLYING_TAB_LOCAL_STORAGE_KEY,
    DEFAULT_UNDERLYING_TAB_CONFIG
  );

  const { isLoading, data, underlyingSearch, sortPreference, error, updateQuery, updateSorting } =
    useUnderlyingMarket(initialUnderlyingTabConfig, saveUnderlyingTabConfig);

  const [searchPhrase, setSearchPhrase] = useState(underlyingSearch);
  const [listHeight, setListHeight] = useState('');

  useEffect(() => {
    const height = `min( ${data.length * rowHeight}px, calc(100% - 80px)`;

    setListHeight(height);
  }, [data.length, rowHeight]);

  useEffect(() => {
    searchInputRef.current!.focus();
  }, [underlyingSearch]);

  // Determine if the list requires scrolling, so the 'scrolling' class is applied
  useEffect(() => {
    const gridBody = document.getElementById('underlying-list-wrapper');

    if (gridBody) {
      const totalRowsHeight = data.length * rowHeight;

      setShowingScrollbar(gridBody.clientHeight < totalRowsHeight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listHeight]);

  useDebouncedEffect(
    () => updateQuery(searchPhrase.trimStart()),
    !searchPhrase.length ? 0 : SEARCH_UPDATE_DELAY,
    searchPhrase
  );

  const handleGoToAdvancedSearch = () => {
    markMTFAdvancedSearchAsSeen();
    setActiveTab(SearchTab.ADVANCED_SEARCH);
  };

  return (
    <>
      <div className={styles.searchResult}>
        <div className={styles.searchBox}>
          <input
            className={styles.searchInput}
            type="text"
            value={searchPhrase}
            placeholder={t('underlying.search-placeholder')}
            data-testid="search-underlying-input"
            maxLength={20}
            onChange={(e) => {
              /* istanbul ignore else */
              if (e.target.value !== underlyingSearch) {
                setSearchPhrase(e.target.value);
              }
            }}
            ref={searchInputRef}
          />
        </div>

        {underlyingSearch?.length < 2 && (
          <div
            className={screenerStyles.searchPrompt}
            data-testid="search-underlying-2-chars-prompt"
          >
            {t('underlying.search-prompt')}
          </div>
        )}

        {!isLoading && (!isWebsiteTheme || (isWebsiteTheme && !data.length)) && (
          <SearchResult
            searchTab={SearchTab.UNDERLYING}
            totalResults={data.length}
            instrumentType={null}
            isLoading={isLoading}
            error={error}
            searchString={underlyingSearch}
          />
        )}

        {!isLoading && error && (
          <p className={screenerStyles.searchPrompt} data-testid="no-records-message">
            {t('error-generic')}
          </p>
        )}
      </div>

      {!isLoading && isWebsiteTheme && data.length > 0 && (
        <SearchResult
          searchTab={SearchTab.UNDERLYING}
          totalResults={data.length}
          instrumentType={null}
          isLoading={isLoading}
          error={error}
          searchString={underlyingSearch}
        />
      )}

      {!error && (
        <div data-testid="search-underlying-grid" className={`${gridStyles.gridWrapper} ${gridStyles.gridWrapperScrollable}`}>
          {isLoading && (
            <div data-testid="underlying-grid-loading" className={gridStyles.gridLoading}></div>
          )}
          {data.length > 0 && (
            <>
              <div
                className={`${gridHeaderStyles.gridHeader} ${
                  showingScrollbar
                    ? gridHeaderStyles.gridHeaderWithScrollbar
                    : // Disabled for now since Jest doesn't simulate 'clientHeight'.
                      // This code should be rewritten in a similar way as e.g. TurboTab.
                      /* istanbul ignore next */ ''
                }`}
                data-testid="underlying-grid-loaded"
              >
                <SingleSelectDropdown
                  placeholder={t('market.label-long')}
                  items={[
                    {
                      displayString: t('sorting.popularity'),
                      key: UnderlyingSort.popularity,
                    },
                    {
                      displayString: t('sorting.asc'),
                      key: UnderlyingSort.asc,
                    },
                    {
                      displayString: t('sorting.desc'),
                      key: UnderlyingSort.desc,
                    },
                  ]}
                  onSelect={(key: UnderlyingSort) => {
                    /* istanbul ignore else */
                    if (JSON.stringify(key) !== JSON.stringify(sortPreference)) {
                      updateSorting(key);
                    }
                  }}
                  classForAnchor={`${gridHeaderStyles.gridHeaderItem} ${gridHeaderStyles.gridHeaderMarketUnderlyingTab}`}
                  selectedItemKey={sortPreference}
                />
                <div
                  className={`${gridHeaderStyles.gridHeaderItem} ${
                    gridHeaderStyles.gridHeaderReferencePrice
                  } ${
                    priceTicksEnabled ? gridHeaderStyles.gridHeaderReferencePriceWithPriceTicks : ''
                  } ${gridHeaderStyles.gridHeaderItemNonSortable}`}
                  data-testid="refPriceHeader"
                  title={t('reference-price.label-long')}
                >
                  {t('reference-price.label-short')}
                </div>
                <div
                  className={`${gridHeaderStyles.gridHeaderItem} ${gridHeaderStyles.gridHeaderChange} ${gridHeaderStyles.gridHeaderItemNonSortable}`}
                  title={t('change.label-long')}
                >
                  {t('change.label-short')}
                </div>
                <div
                  className={`${gridHeaderStyles.gridHeaderItem} ${gridHeaderStyles.gridHeaderPercentChange} ${gridHeaderStyles.gridHeaderItemNonSortable}`}
                  title={t('change-pct.label-long')}
                >
                  {t('change-pct.label-short')}
                </div>
              </div>

              <ul
                className={`${gridStyles.gridAdvancedSearch} ${gridStyles.gridAdvancedSearchSmall}`}
                id="underlying-list-wrapper"
                style={{ height: listHeight }}
                // this inline style allows the promo-link to be shown directly under the grid of results
              >
                {!isLoading && data.length > 0 && (
                  <UnderlyingMarketList
                    data={data}
                    selectUnderlyingMarket={selectUnderlyingMarket}
                    selectedUnderlyingMarketId={selectedUnderlyingMarketId}
                    priceTicksEnabled={priceTicksEnabled}
                  />
                )}
              </ul>
            </>
          )}

          <div className={screenerStyles.promoLink} data-testid="promo-link">
            {!isLoading && underlyingSearch?.length >= 2 && (
              <Trans i18nKey="promo-link">
                You can use{' '}
                <button
                  type="button"
                  className={screenerStyles.promoLinkUrl}
                  data-testid="underlying-tab-promo-link-url"
                  onClick={() => handleGoToAdvancedSearch()}
                >
                  advanced search
                </button>{' '}
                to filter and view our complete list of instruments.
              </Trans>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default UnderlyingTab;

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import styles from '../styles/advanced-search.module.css';
import TurboTab from './TurboTab';
import ConstantTab from './ConstantTab';
import WarrantTab from './WarrantTab';
import { SelectMarketCallback } from './MtfScreener';
import { useActions } from '../hooks/use-actions';
import { useWebsiteScreenerParams } from '../hooks/use-website-screener-params';

export type SetActiveAdvancedSearchTabCallback = (activeTab: AdvancedSearchNavigation) => void;

export enum AdvancedSearchNavigation {
  TURBO = 'Turbo',
  WARRANT = 'Warrant',
  CONSTANT = 'Constant',
}

export type AdvancedSearchTabProps = {
  priceTicksEnabled: boolean;
  selectMarket: SelectMarketCallback;
  selectedMarketId: string;
  showWarrants: boolean;
  showConstants: boolean;
  utcOffset: number;
  activeAdvancedSearchTab: AdvancedSearchNavigation;
  setActiveAdvancedSearchTab: SetActiveAdvancedSearchTabCallback;
};

const AdvancedSearchTab: React.FC<AdvancedSearchTabProps> = ({
  priceTicksEnabled,
  selectMarket,
  selectedMarketId,
  showWarrants,
  showConstants,
  utcOffset,
  activeAdvancedSearchTab,
  setActiveAdvancedSearchTab,
}) => {
  const { t } = useTranslation();
  const { actions } = useActions();
  const { isWebsiteTheme } = useWebsiteScreenerParams();

  const handleTabChange = (selectedAdvancedSearchTab: AdvancedSearchNavigation) => {
    setActiveAdvancedSearchTab(selectedAdvancedSearchTab);

    if (
      selectedAdvancedSearchTab === AdvancedSearchNavigation.TURBO &&
      actions.onSearchInteraction
    ) {
      actions.onSearchInteraction('Open Turbo screener', 'Turbo screener', undefined, 'Turbos');
    }

    if (
      selectedAdvancedSearchTab === AdvancedSearchNavigation.WARRANT &&
      actions.onSearchInteraction
    ) {
      actions.onSearchInteraction(
        'Open Warrant screener',
        'Warrant screener',
        undefined,
        'Warrants'
      );
    }

    if (
      selectedAdvancedSearchTab === AdvancedSearchNavigation.CONSTANT &&
      actions.onSearchInteraction
    ) {
      actions.onSearchInteraction(
        'Open Constant screener',
        'Constant screener',
        undefined,
        'Constants'
      );
    }
  };

  const turboTabComponent = (
    <TurboTab
      priceTicksEnabled={priceTicksEnabled}
      selectMarket={selectMarket}
      selectedMarketId={selectedMarketId}
    />
  );

  const warrantTabComponent = (
    <WarrantTab
      priceTicksEnabled={priceTicksEnabled}
      selectMarket={selectMarket}
      selectedMarketId={selectedMarketId}
      utcOffset={utcOffset}
    />
  );

  const constantTabComponent = (
    <ConstantTab
      priceTicksEnabled={priceTicksEnabled}
      selectMarket={selectMarket}
      selectedMarketId={selectedMarketId}
    />
  );

  useEffect(() => {
    if (activeAdvancedSearchTab === AdvancedSearchNavigation.WARRANT && !showWarrants) {
      setActiveAdvancedSearchTab(AdvancedSearchNavigation.TURBO);
    }

    if (activeAdvancedSearchTab === AdvancedSearchNavigation.CONSTANT && !showConstants) {
      setActiveAdvancedSearchTab(AdvancedSearchNavigation.TURBO);
    }
  }, [activeAdvancedSearchTab, showWarrants, showConstants, setActiveAdvancedSearchTab]);

  const activeTabComponent = () => {
    switch (activeAdvancedSearchTab) {
      case AdvancedSearchNavigation.TURBO:
        return turboTabComponent;
      case AdvancedSearchNavigation.WARRANT:
        return showWarrants ? warrantTabComponent : turboTabComponent;
      case AdvancedSearchNavigation.CONSTANT:
        return showConstants ? constantTabComponent : turboTabComponent;
      default:
        return turboTabComponent;
    }
  };

  return (
    <>
      <div className={styles.advancedSearchNavigation} data-testid="advanced-search-navigation">
        {!isWebsiteTheme && (
          <div className={styles.advancedSearchNavigationLabel}>
            {t('navigation.product-label')}
          </div>
        )}
        <div className={styles.advancedSearchNavigationButtonGroup}>
          <button
            type="button"
            className={`${styles.advancedSearchNavigationButton} ${
              activeAdvancedSearchTab === AdvancedSearchNavigation.TURBO
                ? styles.advancedSearchNavigationButtonActive
                : ''
            }`}
            onClick={() => handleTabChange(AdvancedSearchNavigation.TURBO)}
            data-testid="turbo-tab"
          >
            <div className={`${styles.advancedSearchNavigationButtonLabel} ${
              activeAdvancedSearchTab === AdvancedSearchNavigation.TURBO
                ? styles.advancedSearchNavigationButtonLabelActive
                : ''
            }`}>
              {t('navigation.turbo')}
            </div>
          </button>
          {showWarrants && (
            <>
              <button
                type="button"
                className={`${styles.advancedSearchNavigationButton} ${
                  activeAdvancedSearchTab === AdvancedSearchNavigation.WARRANT
                    ? styles.advancedSearchNavigationButtonActive
                    : ''
                }`}
                onClick={() => handleTabChange(AdvancedSearchNavigation.WARRANT)}
                data-testid="warrant-tab"
              >
                <div className={`${styles.advancedSearchNavigationButtonLabel} ${
                  activeAdvancedSearchTab === AdvancedSearchNavigation.WARRANT
                    ? styles.advancedSearchNavigationButtonLabelActive
                    : ''
                }`}>
                  {t('navigation.warrant')}
                </div>
              </button>
            </>
          )}
          {showConstants && (
            <button
              type="button"
              className={`${styles.advancedSearchNavigationButton} ${
                activeAdvancedSearchTab === AdvancedSearchNavigation.CONSTANT
                  ? styles.advancedSearchNavigationButtonActive
                  : ''
              }`}
              onClick={() => handleTabChange(AdvancedSearchNavigation.CONSTANT)}
              data-testid="constant-tab"
            >
              <div className={`${styles.advancedSearchNavigationButtonLabel} ${
                activeAdvancedSearchTab === AdvancedSearchNavigation.CONSTANT
                  ? styles.advancedSearchNavigationButtonLabelActive
                  : ''
              }`}>
                {t('navigation.constant')}
              </div>
            </button>
          )}
        </div>
      </div>
      {activeTabComponent()}
    </>
  );
};

export default AdvancedSearchTab;

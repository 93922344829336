import React from 'react';
// @ts-ignore
import styles from '../styles/search-result.module.css';
import { SearchTab } from './Tabs';
import { VALID_ISIN_LENGTH } from './IsinTab';
import { InstrumentType } from './MtfScreener';
import { Trans, useTranslation } from 'react-i18next';

export type SearchResultProps = {
  searchTab: SearchTab;
  totalResults: number;
  instrumentType: InstrumentType | null;
  isLoading: boolean;
  error: string | Error | null;
  hasFilters?: boolean;
  searchString?: string;
};

const SearchResult: React.FC<SearchResultProps> = ({
  searchTab,
  totalResults,
  instrumentType,
  isLoading,
  error,
  hasFilters,
  searchString,
}) => {
  const { i18n, t } = useTranslation();

  const searchStringLength = !searchString ? 0 : searchString.length;

  return (
    <>
      {searchTab === SearchTab.UNDERLYING && !error && (
        <>
          {totalResults > 0 && (
            <div className={styles.searchResult}>
              <div className={styles.searchResultTotalFound} data-testid="search-result-total-found">
                <Trans i18nKey="underlying.underlyings-found" count={totalResults}>
                  <strong>{{ totalResults }}</strong> underlying found
                </Trans>
              </div>
            </div>
          )}

          {!isLoading && totalResults === 0 && searchString && searchString.length > 1 && (
            <div className={styles.searchResultError}>
              <div
                className={styles.searchResultBannerMessage}
                data-testid="search-result-banner-message"
              >
                <Trans i18nKey="underlying.not-found">
                  Sorry, no results found for <strong>"{{ searchString }}"</strong>
                </Trans>
              </div>
            </div>
          )}
        </>
      )}

      {searchTab === SearchTab.ISIN && error && (
        <div className={styles.searchResultError}>
          <div
            className={styles.searchResultBannerMessage}
            data-testid="search-result-banner-message"
          >
            {!i18n.exists(error.toString()) ? t('error-generic') : t(error.toString())}
          </div>
        </div>
      )}

      {searchTab === SearchTab.ISIN && !error && (
        <>
          {totalResults === 1 && (
            <div className={styles.searchResult}>
              <div className={styles.searchResultTotalFound} data-testid="search-result-total-found">
                {instrumentType === InstrumentType.CONSTANT && (
                  <Trans i18nKey="filters.constants-found" count={totalResults}>
                    <strong>{{ totalResults }}</strong> constant certificate found
                  </Trans>
                )}
                {instrumentType === InstrumentType.TURBO && (
                  <Trans i18nKey="filters.turbos-found" count={totalResults}>
                    <strong>{{ totalResults }}</strong> turbo found
                  </Trans>
                )}
                {instrumentType === InstrumentType.WARRANT && (
                  <Trans i18nKey="filters.warrants-found" count={totalResults}>
                    <strong>{{ totalResults }}</strong> warrant found
                  </Trans>
                )}
              </div>
            </div>
          )}

          {!isLoading && totalResults === 0 && searchString?.length === VALID_ISIN_LENGTH && (
            <div className={styles.searchResultError}>
              <div
                className={styles.searchResultBannerMessage}
                data-testid="search-result-banner-message"
              >
                <Trans i18nKey="isin.not-found">
                  Sorry, no ISIN found for <strong>"{{ searchString }}"</strong>
                </Trans>
              </div>
            </div>
          )}

          {!isLoading && searchString && searchString.length !== VALID_ISIN_LENGTH && (
            <div className={styles.searchResultError}>
              <div
                className={styles.searchResultBannerMessage}
                data-testid="search-result-banner-message"
              >
                <Trans i18nKey="isin.incorrect-char-number">
                  Number of characters (<strong>{{ searchStringLength }}</strong>) does not correspond
                  to ISIN (<strong>12</strong>)
                </Trans>
              </div>
            </div>
          )}
        </>
      )}

      {searchTab === SearchTab.TURBO && !error && (
        <div className={styles.searchResult}>
          <div className={styles.searchResultTotalFound}>
            <Trans i18nKey="filters.turbos-found" count={totalResults}>
              <strong>{{ totalResults }}</strong> turbo found
            </Trans>
          </div>

          {!isLoading && hasFilters && totalResults === 0 && (
            <div className={styles.searchResultBannerNoResult}>
              {t('filters.no-result-from-filters')}
            </div>
          )}
        </div>
      )}

      {searchTab === SearchTab.WARRANT && !error && (
        <div className={styles.searchResult}>
          <div className={styles.searchResultTotalFound}>
            <Trans i18nKey="filters.warrants-found" count={totalResults}>
              <strong>{{ totalResults }}</strong> warrant found
            </Trans>
          </div>

          {!isLoading && hasFilters && totalResults === 0 && (
            <div className={styles.searchResultBannerNoResult}>
              {t('filters.no-result-from-filters')}
            </div>
          )}
        </div>
      )}

      {searchTab === SearchTab.CONSTANT && !error && (
        <div className={styles.searchResult}>
          <div className={styles.searchResultTotalFound}>
            <Trans i18nKey="filters.constants-found" count={totalResults}>
              <strong>{{ totalResults }}</strong> constant certificate found
            </Trans>
          </div>

          {!isLoading && hasFilters && totalResults === 0 && (
            <div className={styles.searchResultBannerNoResult}>
              {t('filters.no-result-from-filters')}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default SearchResult;

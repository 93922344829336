// @ts-ignore
import fetch from '../../fetch-shim';

type FetchOptions = {
  signal: AbortSignal | null;
  headers: {
    [key: string]: string;
  };
};
type Param = string | boolean | number | undefined;

const toParam = (key: string, value: Param) => `${key}=${value}`;

export const toUrl = (host: string, path: string, params?: { [key: string]: Param | string[] }) =>
  `${host}${path}${
    params
      ? '?' +
        Object.keys(params)
          .reduce((memo: string[], key: string) => {
            const value: Param | string[] = params[key];
            if (Array.isArray(value)) {
              return [...value.map((v) => toParam(key, v)), ...memo];
            } else if (value === undefined) {
              return memo;
            }
            return [toParam(key, value), ...memo];
          }, [])
          .join('&')
      : ''
  }`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FetchFn = (url: string, opts?: Record<string, any>) => Promise<Response>;
type FetchData = (
  xst: string | null,
  cst: string | null,
  accountId?: string
) => [FetchFn, () => void];

/* istanbul ignore next */
const fetchData: FetchData = (xst, cst, accountId) => {
  let signal: AbortSignal | null = null;
  let controller: AbortController | null = null;

  if (window.AbortController) {
    controller = new AbortController();
    signal = controller.signal;
  }

  return [
    (url: string, opts = {}) => {
      const fetchOptions: FetchOptions = {
        signal,
        headers: {
          'content-type': 'application/json; charset=UTF-8',
        },
        ...opts,
      };

      if (cst) {
        fetchOptions.headers['cst'] = cst;
      }

      if (xst) {
        fetchOptions.headers['x-security-token'] = xst;
      }

      if (accountId) {
        fetchOptions.headers['ig-account-id'] = accountId;
      }

      return fetch(url, fetchOptions);
    },
    () => {
      controller && controller.abort();
    },
  ];
};

export default fetchData;

import React, { useEffect, useRef, useState } from 'react';
// @ts-ignore
import styles from '../styles/isin-tab.module.css';
// @ts-ignore
import screenerStyles from '../styles/index.module.css';
import { Trans, useTranslation } from 'react-i18next';
import useIsin, {
  DEFAULT_ISIN_TAB_CONFIG,
  ISIN_TAB_SESSION_STORAGE_KEY,
  IsinTabConfig,
} from '../hooks/use-isin';
import useSessionStorage from '../hooks/use-session-storage';
import { SearchTab, SetActiveTabCallback } from './Tabs';
import SearchResult from './SearchResult';
import {
  InstrumentType,
  MarkMTFAdvancedSearchAsSeenCallback,
  SelectMarketCallback,
  SetIsinInfoBoxAsDismissedCallback,
} from './MtfScreener';
import { useDebouncedEffect } from '../utils/debounce-utils';
import IsinList from './IsinList';
import { useWebsiteScreenerParams } from '../hooks/use-website-screener-params';

export const SEARCH_UPDATE_DELAY = 1000;
export const VALID_ISIN_LENGTH = 12;

export type SaveIsinTabCallback = (config: IsinTabConfig) => void;

export type IsinTabProps = {
  isIsinInfoBoxDismissed: boolean;
  priceTicksEnabled: boolean;
  selectMarket: SelectMarketCallback;
  selectedMarketId: string;
  setIsinInfoBoxAsDismissed: SetIsinInfoBoxAsDismissedCallback;
  utcOffset: number;
  setActiveTab: SetActiveTabCallback;
  markMTFAdvancedSearchAsSeen: MarkMTFAdvancedSearchAsSeenCallback;
};

const IsinTab: React.FC<IsinTabProps> = ({
  isIsinInfoBoxDismissed,
  priceTicksEnabled,
  selectMarket,
  selectedMarketId,
  setIsinInfoBoxAsDismissed,
  utcOffset,
  setActiveTab,
  markMTFAdvancedSearchAsSeen,
}) => {
  const { t } = useTranslation();
  const { isWebsiteTheme } = useWebsiteScreenerParams();

  const [isSearchTriggered, setIsSearchTriggered] = useState(false);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [initialIsinTabConfig, saveIsinTabConfig] = useSessionStorage(
    ISIN_TAB_SESSION_STORAGE_KEY,
    DEFAULT_ISIN_TAB_CONFIG
  );

  const {
    error,
    isLoading,
    resetInstrument,
    searchPhrase: isinSearchPhrase,
    instrument,
    updateSearchPhrase,
  } = useIsin(initialIsinTabConfig, saveIsinTabConfig);
  const [searchPhrase, setSearchPhrase] = useState(isinSearchPhrase);

  useEffect(() => {
    searchInputRef.current!.focus();
    setIsSearchTriggered(true);
  }, []);

  const handleSearchInputChange = () => {
    /* istanbul ignore else */
    if (searchPhrase !== isinSearchPhrase) {
      setIsSearchTriggered(false);
      resetInstrument();
      updateSearchPhrase(searchPhrase);
      setIsSearchTriggered(true);
    }
  };

  const handleGoToAdvancedSearch = () => {
    markMTFAdvancedSearchAsSeen();
    setActiveTab(SearchTab.ADVANCED_SEARCH);
  };

  useDebouncedEffect(
    () => handleSearchInputChange(),
    searchPhrase.length === VALID_ISIN_LENGTH ? 0 : SEARCH_UPDATE_DELAY,
    searchPhrase,
    () => setIsSearchTriggered(false)
  );

  const isTurboScreenerLinkHidden =
    instrument && instrument.instrumentType === InstrumentType.CONSTANT;

  return (
    <div>
      <div className={styles.isinTabContainer}>
        <div className={styles.isinTabSearch}>
          <form onSubmit={/* istanbul ignore next */ (event) => event.preventDefault()}>
            <input
              className={styles.isinTabSearchInput}
              type="text"
              name="isin-search-input"
              placeholder={t('isin.search-input-placeholder')}
              value={searchPhrase}
              onChange={(event) => setSearchPhrase(event.target.value)}
              ref={searchInputRef}
              data-testid="isin-search-input"
            />
          </form>
        </div>
        <div className={styles.isinTabNote}>{t('isin.search-note')}</div>

        {isSearchTriggered && (!isWebsiteTheme || (isWebsiteTheme && !instrument)) && (
          <SearchResult
            totalResults={!instrument ? 0 : 1}
            error={error}
            instrumentType={!instrument ? null : instrument.instrumentType}
            isLoading={isLoading}
            searchTab={SearchTab.ISIN}
            searchString={searchPhrase}
          />
        )}
      </div>

      {isSearchTriggered && isWebsiteTheme && instrument && (
        <SearchResult
          totalResults={1}
          error={error}
          instrumentType={instrument.instrumentType}
          isLoading={isLoading}
          searchTab={SearchTab.ISIN}
          searchString={searchPhrase}
        />
      )}

      {(isLoading || instrument) && (
        <IsinList
          instrument={instrument}
          isLoading={isLoading}
          priceTicksEnabled={priceTicksEnabled}
          selectMarket={selectMarket}
          selectedMarketId={selectedMarketId}
          utcOffset={utcOffset}
        />
      )}
      {!isIsinInfoBoxDismissed && (
        <div className={styles.isinTabDescription} data-testid="isin-tab-description">
          <button
            className={styles.minimiseIcon}
            onClick={() => setIsinInfoBoxAsDismissed()}
            data-testid="description-close-button"
          />
          <h1 className={styles.isinTabDescriptionHeader}>{t('isin.description.header')}</h1>
          <div className={styles.isinTabDescriptionText}>
            <Trans i18nKey="isin.description.line-1">
              <strong>ISIN</strong> is a unique identifier for securities. You can use it to locate
              specific instruments inside our trading platform.
            </Trans>
          </div>
          <div className={styles.isinTabDescriptionText}>
            <Trans i18nKey="isin.description.line-2">
              An <strong>ISIN</strong> is 12 characters long (e.g. DE000A228EU7).
            </Trans>
          </div>
        </div>
      )}
      {!isTurboScreenerLinkHidden && (
        <div className={screenerStyles.promoLink}>
          <Trans i18nKey="promo-link">
            You can use{' '}
            <button
              type="button"
              className={screenerStyles.promoLinkUrl}
              data-testid="isin-tab-promo-link-url"
              onClick={() => handleGoToAdvancedSearch()}
            >
              advanced search
            </button>{' '}
            to filter and view our complete list of instruments.
          </Trans>
        </div>
      )}
    </div>
  );
};

export default IsinTab;

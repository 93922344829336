import {
  Direction,
  FilterInteraction,
  Underlying,
  UnderlyingListItem,
} from '../components/Filters';
import { FiltersState } from '../components/Filters';

export type Action =
  | { type: 'SET_UNDERLYINGS'; data: UnderlyingListItem[] }
  | {
      type: 'TOGGLE_UNDERLYING_ITEM';
      underlyingType: string;
      underlyingId: string;
    }
  | {
      type: 'UPDATE_NUMERIC_FILTER';
      key: keyof typeof FilterInteraction;
      value: number | undefined;
    }
  | { type: 'UPDATE_DIRECTION'; direction: Direction }
  | { type: 'RESET_FILTERS' };

const filtersReducer = (state: FiltersState, action: Action): FiltersState => {
  switch (action.type) {
    case 'SET_UNDERLYINGS':
      const { data } = action;

      return {
        ...state,
        underlyings: data,
      };
    case 'TOGGLE_UNDERLYING_ITEM':
      const { underlyingId, underlyingType: typeKey } = action;
      const updatedUnderlyings = state.underlyings.map((item: UnderlyingListItem) => {
        if (item.category !== typeKey) {
          return item;
        }

        return {
          category: item.category,
          categoryName: item.categoryName,
          underlyings: item.underlyings.map((item: Underlying) => {
            if (item.underlyingId === underlyingId) {
              item.isChecked = !item.isChecked;
            }

            return item;
          }),
        };
      });

      return {
        ...state,
        underlyings: updatedUnderlyings,
      };

    case 'UPDATE_DIRECTION':
      const { direction } = action;
      return { ...state, direction };

    case 'UPDATE_NUMERIC_FILTER':
      return {
        ...state,
        [action.key]: action.value,
      };

    case 'RESET_FILTERS':
      return {
        ...state,
        underlyings: state.underlyings.map((un: UnderlyingListItem) => {
          un.underlyings.forEach((item) => {
            item.isChecked = false;
          });

          return un;
        }),
        direction: Direction.ALL,
        koLevelMin: undefined,
        koLevelMax: undefined,
        distanceToKOMin: undefined,
        distanceToKOMax: undefined,
        leverageMin: undefined,
        leverageMax: undefined,
      };
    /* istanbul ignore next */
    default:
      return state;
  }
};

export default filtersReducer;

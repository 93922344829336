import React, { RefObject, useRef } from 'react';
import DatePicker from 'react-datepicker';

export type ExpiryInputProps = {
  className: string;
  calendarClassName: string;
  dateFormat: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  minDate: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: any;
  placeholderText: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selected: any;
  tabIndex: number;
};

const ExpiryInput: React.FC<ExpiryInputProps> = ({
  className,
  calendarClassName,
  dateFormat,
  minDate,
  onChange,
  placeholderText,
  selected,
  tabIndex,
}) => {
  const expiryDatepickerRef: RefObject<DatePicker> = useRef(null);

  return (
    <DatePicker
      className={className}
      calendarClassName={calendarClassName}
      dateFormat={dateFormat}
      minDate={minDate}
      onChange={onChange}
      // React Datepicker has a bug that causes blur event not being triggered
      // when using Tab key. Because of that the calendar part stays open in such case.
      // This fix manually sets open state of the datepicker to false but only
      // if the Tab key was used. In other cases, e.g. when clicking outside the calendar part,
      // blur event is triggered correctly and setting open state manually breaks the functionality.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onKeyDown={(event: any) => {
        if (event.keyCode === 9) {
          // Without using requestAnimationFrame tab index stops working correctly in Firefox
          window.requestAnimationFrame(() => {
            expiryDatepickerRef.current!.setOpen(false);
          });
        }
      }}
      placeholderText={placeholderText}
      ref={expiryDatepickerRef}
      selected={selected}
      tabIndex={tabIndex}
      data-testid="expiry-input"
    />
  );
};

export default ExpiryInput;

import React, { useEffect, useRef } from 'react';
// @ts-ignore
import styles from '../styles/price-tick.module.css';

const CLASSNAME_DOWN = styles.priceTickDown;
const CLASSNAME_DOWN_2 = styles.priceTickDown2;
const CLASSNAME_UP = styles.priceTickUp;
const CLASSNAME_UP_2 = styles.priceTickUp2;

function usePrevious<T>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const PriceTick: React.FC<{ price: number; className?: string }> = React.memo(
  ({ className, price }) => {
    const previousPrice = usePrevious(price);
    const previousClassRef = useRef('');
    let directionClass = '';
    if (previousPrice) {
      if (price > previousPrice) {
        directionClass = previousClassRef.current === CLASSNAME_UP ? CLASSNAME_UP_2 : CLASSNAME_UP;
      } else if (price < previousPrice) {
        directionClass =
          previousClassRef.current === CLASSNAME_DOWN ? CLASSNAME_DOWN_2 : CLASSNAME_DOWN;
      }
      previousClassRef.current = directionClass;
    }
    return <span className={`${styles.priceTick} ${directionClass} ${className}`}>{price}</span>;
  }
);

export default PriceTick;

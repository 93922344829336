import React, { CSSProperties, useEffect, useMemo, useState } from 'react';
// @ts-ignore
import gridStyles from '../styles/grid.module.css';
import { InstrumentType, SelectMarketCallback, Warrant } from './MtfScreener';
import { useTranslation } from 'react-i18next';
import { presentNumber } from '../utils/rounding-utils';
import moment from 'moment-timezone';
import PriceTick from './PriceTick';
import { useSessionData } from '../hooks/use-session-data';
import { useLsSubscription } from '@ig-wpx/lightstreamer';
import { useWebsiteScreenerParams } from '../hooks/use-website-screener-params';

export type WarrantRowProps = {
  selectMarket: SelectMarketCallback;
  selectedMarketId: string;
  style: CSSProperties;
  utcOffset: number;
  warrant: Warrant;
  priceTicksEnabled: boolean;
};

const WarrantRow: React.FC<WarrantRowProps> = ({
  warrant,
  style,
  selectMarket,
  selectedMarketId,
  utcOffset,
  priceTicksEnabled,
}) => {
  const { t } = useTranslation();
  const { currentAccountId } = useSessionData();
  const { allowUnauthorizedAPI } = useWebsiteScreenerParams();

  const [bid, setBid] = useState<number>();
  const [offer, setOffer] = useState<number>();
  const [marketAvailability, setMarketAvailability] = useState<boolean>(true);

  const NO_VALUE = '-';
  const FIELDS = ['bid', 'offer', 'market_status'];
  const SUBSCRIPTION_URL = allowUnauthorizedAPI
    ? `V2-F-BID,OFR|${warrant.epic}`
    : `MARKET_V4:${currentAccountId}:${warrant.epic}`;

  const subscriptionItems = useMemo(() => [SUBSCRIPTION_URL], [SUBSCRIPTION_URL]);

  const { data } = useLsSubscription(subscriptionItems, FIELDS, 'MERGE');

  useEffect(() => {
    if (!data) {
      return;
    }

    /* istanbul ignore else */
    if (data.bid) {
      setBid(parseFloat(data.bid));
    }

    /* istanbul ignore else */
    if (data.offer) {
      setOffer(parseFloat(data.offer));
    }

    const isMarketAvailable = data.market_status !== 'UNAVAILABLE';

    setMarketAvailability(isMarketAvailable);
  }, [data]);

  const showCellData = (cellData: undefined | string | number) => {
    return cellData ? cellData.toString() : NO_VALUE;
  };

  const showCellNumberData = (cellData: undefined | number) => {
    return cellData === undefined || isNaN(cellData) ? NO_VALUE : presentNumber(cellData);
  };

  const showCellNumberDataWithTicks = (cellData: undefined | number) => {
    return cellData === undefined || isNaN(cellData) ? (
      <span className={gridStyles.noPriceTickPlaceholder}>{NO_VALUE}</span>
    ) : (
      <PriceTick price={+presentNumber(cellData)} />
    );
  };

  const showAsDate = (cellData: undefined | number) => {
    if (cellData === undefined || isNaN(cellData)) {
      return NO_VALUE;
    }

    return moment(cellData).utcOffset(utcOffset).format('DD/MM/YY');
  };

  const showDirection = (optionType: undefined | string) => {
    return optionType ? t(`direction-warrant.${optionType.toLowerCase()}`) : NO_VALUE;
  };

  const loadedRow = (
    <li
      className={`${gridStyles.gridRow} ${
        warrant.id === selectedMarketId ? gridStyles.gridRowSelected : ''
      } ${!marketAvailability ? gridStyles.gridRowDisabled : ''}`}
      data-testid="warrant-list-row"
      style={style}
      onClick={() => {
        /* istanbul ignore else */
        if (marketAvailability) {
          selectMarket({ ...warrant, instrumentType: InstrumentType.WARRANT });
        }
      }}
    >
      <div
        className={`${gridStyles.gridRowItem} ${gridStyles.gridWarrantRowMarket}`}
        title={showCellData(warrant.underlyingName)}
        data-testid="underlying-name"
      >
        {showCellData(warrant.underlyingName)}
      </div>
      <div
        className={`${gridStyles.gridRowItem} ${gridStyles.gridRowDirection}`}
        title={showDirection(warrant.optionType)}
        data-testid="option-type"
      >
        {showDirection(warrant.optionType)}
      </div>
      <div
        className={`${gridStyles.gridRowItem} ${gridStyles.gridRowStrikeLevel}`}
        title={showCellNumberData(warrant.strikeLevel)}
        data-testid="strike-level"
      >
        {showCellNumberData(warrant.strikeLevel)}
      </div>
      <div
        className={`${gridStyles.gridRowItem} ${gridStyles.gridRowUnderlyingLevel}`}
        title={showCellNumberData(warrant.underlyingLevel)}
      >
        {showCellNumberData(warrant.underlyingLevel)}
      </div>
      <div
        className={`${gridStyles.gridRowItem} ${gridStyles.gridRowExpiry}`}
        title={showAsDate(warrant.expiry)}
        data-testid="expiry-grid-cell"
      >
        {showAsDate(warrant.expiry)}
      </div>
      <div
        className={`${gridStyles.gridRowItem} ${gridStyles.gridRowSell}`}
        title={showCellNumberData(bid)}
        data-testid="bid"
      >
        {priceTicksEnabled ? showCellNumberDataWithTicks(bid) : showCellNumberData(bid)}
      </div>
      <div
        className={`${gridStyles.gridRowItem} ${gridStyles.gridRowBuy}`}
        title={showCellNumberData(offer)}
        data-testid="offer"
      >
        {showCellNumberDataWithTicks(offer)}
      </div>
    </li>
  );

  const loadingRow = (
    <li
      className={`${gridStyles.gridRow} ${gridStyles.gridRowLoading}`}
      data-testid="row-loading"
      style={style}
    >
      <div className={gridStyles.gridRowLoadingAnimation}></div>
    </li>
  );

  return warrant.isLoading ? loadingRow : loadedRow;
};

export default WarrantRow;

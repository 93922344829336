import React, { useEffect, useMemo, useState } from 'react';
// @ts-ignore
import gridHeaderStyles from '../styles/grid-header.module.css';
// @ts-ignore
import gridStyles from '../styles/grid.module.css';
// @ts-ignore
import screenerStyles from '../styles/index.module.css';
import { SelectMarketCallback, Warrant } from './MtfScreener';
import WarrantFilters, {
  filtersApplied,
  Underlying,
  UnderlyingListItem,
  WarrantFiltersState,
  WarrantSortingState,
} from './WarrantFilters';
import useWarrant, { WarrantSortableFields } from '../hooks/use-warrant';
import { useTranslation } from 'react-i18next';
import WarrantList from './WarrantList';
import { useWebsiteScreenerParams } from '../hooks/use-website-screener-params';

declare global {
  interface Window {
    __localeId__: string;
  }
}

export const WARRANT_ROWS_PER_PAGE = 100;

export type WarrantTabState = {
  apiHost: string;
  isLoading: boolean;
  data: Warrant[];
  pageNumber: number;
  totalResults: number;
  totalPages: number;
  error: Error | string | null;
  filters: WarrantFiltersState;
  sorting: WarrantSortingState;
  url: string;
  allowUnauthorizedAPI: boolean;
  locale: string;
};

export type WarrantMeta = {
  pageNumber: number;
  pageSize: number;
  totalPages: number;
  totalResults: number;
};

export type WarrantResponse = {
  meta: WarrantMeta;
  results: {
    instruments: Warrant[];
    pageNumber: number;
    pageSize: number;
  };
};

export type WarrantTabProps = {
  priceTicksEnabled: boolean;
  selectMarket: SelectMarketCallback;
  selectedMarketId: string;
  utcOffset: number;
};

const WarrantTab: React.FC<WarrantTabProps> = ({
  priceTicksEnabled,
  selectMarket,
  selectedMarketId,
  utcOffset,
}) => {
  const { t } = useTranslation();
  const { rowHeight } = useWebsiteScreenerParams();

  const [showingScrollbar, setShowingScrollbar] = useState<boolean>(true);

  const {
    data,
    error,
    isLoading,
    totalResults,
    loadNextPage,
    filters,
    updateFilters,
    sorting,
    updateSorting,
    trackFilterInteraction,
  } = useWarrant();

  useEffect(() => {
    const gridBody = document.getElementById('warrant-list-wrapper');

    if (gridBody) {
      const totalRowsHeight = data.length * rowHeight;
      setShowingScrollbar(gridBody.clientHeight < totalRowsHeight);
    }
  }, [data.length, rowHeight]);

  const columnHeaderSortedClass = (column: WarrantSortableFields) => {
    if (sorting.sortBy !== column) {
      return gridHeaderStyles.gridHeaderItemSortable;
    }

    return sorting.sortAsc
      ? gridHeaderStyles.gridHeaderItemSortedAsc
      : gridHeaderStyles.gridHeaderItemSortedDesc;
  };

  const sortColumn = (sortBy: WarrantSortableFields) => {
    if (sortBy === sorting.sortBy) {
      updateSorting({
        sortBy,
        sortAsc: !sorting.sortAsc,
      });
    } else {
      const defaultDesc = sortBy === WarrantSortableFields.expiry;

      updateSorting({
        sortBy,
        sortAsc: !defaultDesc,
      });
    }
  };

  const preselectedUnderlyings = useMemo(
    () =>
      filters.underlyings.reduce(
        (total: Underlying[], current: UnderlyingListItem) => total.concat(current.underlyings),
        []
      ),
    [filters.underlyings]
  );

  return (
    <section className={screenerStyles.mtfScreener} data-testid="warrant-tab-section">
      <WarrantFilters
        filters={filters}
        updateFilters={updateFilters}
        trackFilterInteraction={trackFilterInteraction}
        preselectedUnderlyings={preselectedUnderlyings}
        totalResults={totalResults}
        error={error}
        isLoading={isLoading}
      />
      <div className={`${gridStyles.gridWrapper} ${gridStyles.gridWrapperScrollable}`} data-testid="warrant-grid-results">
        {isLoading && (
          <div data-testid="warrant-grid-loading" className={gridStyles.gridLoading}></div>
        )}
        {!isLoading && error && (
          <p className={screenerStyles.searchPrompt} data-testid="no-records-message">
            {t('error-generic')}
          </p>
        )}

        {!error && !(totalResults === 0 && filtersApplied(filters)) && (
          <>
            <div
              className={`${gridHeaderStyles.gridHeader} ${
                showingScrollbar ? gridHeaderStyles.gridHeaderWithScrollbar : ''
              }`}
            >
              <div
                className={`${gridHeaderStyles.gridHeaderItem} ${
                  gridHeaderStyles.gridWarrantHeaderMarket
                } ${columnHeaderSortedClass(WarrantSortableFields.underlyingName)}`}
                onClick={() => sortColumn(WarrantSortableFields.underlyingName)}
                title={t('underlying.label-long')}
                data-testid="instrumentNameHeader"
              >
                {t('underlying.label-short')}
              </div>
              <div
                className={`${gridHeaderStyles.gridHeaderItem} ${
                  gridHeaderStyles.gridHeaderDirection
                } ${columnHeaderSortedClass(WarrantSortableFields.optionType)}`}
                onClick={() => sortColumn(WarrantSortableFields.optionType)}
                title={t('direction-warrant.label-long')}
                data-testid="directionHeader"
              >
                {t('direction-warrant.label-short')}
              </div>
              <div
                className={`${gridHeaderStyles.gridHeaderItem} ${
                  gridHeaderStyles.gridHeaderStrikeLevel
                } ${columnHeaderSortedClass(WarrantSortableFields.strikeLevel)}`}
                onClick={() => sortColumn(WarrantSortableFields.strikeLevel)}
                title={t('strike.label-long')}
                data-testid="strikeLevelHeader"
              >
                {t('strike.label-short')}
              </div>
              <div
                className={`${gridHeaderStyles.gridHeaderItem} ${
                  gridHeaderStyles.gridHeaderUnderlyingLevel
                } ${columnHeaderSortedClass(WarrantSortableFields.underlyingLevel)}`}
                onClick={() => sortColumn(WarrantSortableFields.underlyingLevel)}
                title={t('underlying-level.label-long')}
                data-testid="ulLevelHeader"
              >
                {t('underlying-level.label-short')}
              </div>
              <div
                className={`${gridHeaderStyles.gridHeaderItem} ${
                  gridHeaderStyles.gridHeaderExpiry
                } ${columnHeaderSortedClass(WarrantSortableFields.expiry)}`}
                onClick={() => sortColumn(WarrantSortableFields.expiry)}
                title={t('expiry.label-long')}
                data-testid="expiryHeader"
              >
                {t('expiry.label-short')}
              </div>
              <div
                className={`${gridHeaderStyles.gridHeaderItem} ${gridHeaderStyles.gridHeaderSell} ${
                  priceTicksEnabled ? gridHeaderStyles.gridHeaderSellBuyWithPriceTicks : ''
                }`}
                title={t('list-header.sell')}
                data-testid="sellHeader"
              >
                {t('list-header.sell')}
              </div>
              <div
                className={`${gridHeaderStyles.gridHeaderItem} ${gridHeaderStyles.gridHeaderBuy} ${
                  priceTicksEnabled ? gridHeaderStyles.gridHeaderSellBuyWithPriceTicks : ''
                }`}
                title={t('list-header.buy')}
                data-testid="buyHeader"
              >
                {t('list-header.buy')}
              </div>
            </div>

            <ul id="warrant-list-wrapper" className={gridStyles.gridAdvancedSearch}>
              {!isLoading && data && (
                <WarrantList
                  data={data}
                  loadNextPage={loadNextPage}
                  selectMarket={selectMarket}
                  selectedMarketId={selectedMarketId}
                  totalResults={totalResults}
                  utcOffset={utcOffset}
                  priceTicksEnabled={priceTicksEnabled}
                />
              )}
            </ul>
          </>
        )}
      </div>
    </section>
  );
};

export default WarrantTab;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Goa31erdPgvhNhRmy8pjBA\\=\\= {\n  position: relative;\n  float: right;\n  height: 14px;\n  margin: 3px -12px 0 17px;\n  padding: 2px 4px 3px 3px;\n  border-top-right-radius: 2px;\n  border-bottom-right-radius: 2px;\n  background-color: var(--arrow-badge-background);\n  color: var(--arrow-badge-text);\n  font-weight: 500;\n  font-size: 9px;\n  line-height: 1.1;\n  text-transform: uppercase;\n  /*Create a new stacking context */\n  transform: translateX(0px);\n}\n\n.Goa31erdPgvhNhRmy8pjBA\\=\\=::before {\n  content: \"\";\n  position: absolute;\n  top: 2px;\n  width: 10px;\n  height: 10px;\n  left: -5px;\n  background: var(--arrow-badge-background);\n  transform: rotate(45deg);\n  z-index: -1;\n}\n", "",{"version":3,"sources":["webpack://./src/styles/arrow-badge.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,YAAY;EACZ,wBAAwB;EACxB,wBAAwB;EACxB,4BAA4B;EAC5B,+BAA+B;EAC/B,+CAA+C;EAC/C,8BAA8B;EAC9B,gBAAgB;EAChB,cAAc;EACd,gBAAgB;EAChB,yBAAyB;EACzB,iCAAiC;EACjC,0BAA0B;AAC5B;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,QAAQ;EACR,WAAW;EACX,YAAY;EACZ,UAAU;EACV,yCAAyC;EACzC,wBAAwB;EACxB,WAAW;AACb","sourcesContent":[".arrowBadge {\n  position: relative;\n  float: right;\n  height: 14px;\n  margin: 3px -12px 0 17px;\n  padding: 2px 4px 3px 3px;\n  border-top-right-radius: 2px;\n  border-bottom-right-radius: 2px;\n  background-color: var(--arrow-badge-background);\n  color: var(--arrow-badge-text);\n  font-weight: 500;\n  font-size: 9px;\n  line-height: 1.1;\n  text-transform: uppercase;\n  /*Create a new stacking context */\n  transform: translateX(0px);\n}\n\n.arrowBadge::before {\n  content: \"\";\n  position: absolute;\n  top: 2px;\n  width: 10px;\n  height: 10px;\n  left: -5px;\n  background: var(--arrow-badge-background);\n  transform: rotate(45deg);\n  z-index: -1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"arrowBadge": "Goa31erdPgvhNhRmy8pjBA=="
};
export default ___CSS_LOADER_EXPORT___;

import { useEffect, useReducer } from 'react';
import abortableFetch, { toUrl } from '../utils/fetch';
import {
  InstrumentMeta,
  INSTRUMENTS_URL,
  INSTRUMENTS_URL_UNAUTHORIZED,
  SaveUnderlyingTabCallback,
  UnderlyingMarket,
} from '../components/MtfScreener';
import underlyingMarketReducer from './underlying-market-reducer';
import { useActions } from './use-actions';
import { useSessionData } from './use-session-data';
import { useWebsiteScreenerParams } from './use-website-screener-params';
import { getUnderlyingMarketURLParams } from '../utils/url-utils';

export enum UnderlyingSort {
  popularity = '-underlyingPopularity,underlyingName',
  asc = '+underlyingName',
  desc = '-underlyingName',
}

export type UnderlyingMarketURLParams = {
  pageSize: number;
  defaultForUnderlyingOnly: boolean;
  fields: string;
  includes: string;
  searchTerm: string;
  sort?: string;
  locale?: string;
};

export const SEARCH_UNDERLYING_PAGE_SIZE = 100;
export const UNDERLYING_TAB_LOCAL_STORAGE_KEY = 'underlying-tab-config';
export const DEFAULT_UNDERLYING_TAB_CONFIG: UnderlyingTabConfig = {
  underlyingSearch: '',
  sortPreference: UnderlyingSort.popularity,
};

export type UnderlyingTabConfig = {
  underlyingSearch: string;
  sortPreference: UnderlyingSort;
};

export type UnderlyingsResponse = {
  meta: InstrumentMeta;
  results: {
    instruments: UnderlyingMarket[];
  };
};

const useUnderlyingMarket = (
  savedUnderlyingTabConfig: UnderlyingTabConfig,
  saveUnderlyingTabConfig: SaveUnderlyingTabCallback
) => {
  const { allowUnauthorizedAPI, locale } = useWebsiteScreenerParams();
  const { apiHost, cst, currentAccountId, xst } = useSessionData();
  const { actions } = useActions();

  const [state, dispatch] = useReducer(underlyingMarketReducer, {
    isLoading: true,
    pageNumber: 0,
    totalResults: 0,
    totalPages: 0,
    error: null,
    data: [],
    underlyingSearch: savedUnderlyingTabConfig.underlyingSearch,
    sortPreference: savedUnderlyingTabConfig.sortPreference,
  });

  useEffect(() => {
    if (state.underlyingSearch.length < 2) {
      dispatch({
        type: 'LOADING_COMPLETE',
        instruments: [],
        pageNumber: 0,
        totalPages: 1,
        totalResults: 0,
      });
      return;
    }

    const urlParams: UnderlyingMarketURLParams = getUnderlyingMarketURLParams(
      SEARCH_UNDERLYING_PAGE_SIZE,
      state.underlyingSearch,
      state.sortPreference
    );

    const path = allowUnauthorizedAPI ? INSTRUMENTS_URL_UNAUTHORIZED : INSTRUMENTS_URL;

    if (allowUnauthorizedAPI) {
      urlParams.locale = locale.slice(0, 5);
    }

    const url = toUrl(apiHost, path, urlParams);

    const [fetch, abort] = allowUnauthorizedAPI
      ? abortableFetch(null, null)
      : abortableFetch(xst, cst, currentAccountId);

    /* istanbul ignore else */
    if (actions.onSearch) {
      actions.onSearch(state.underlyingSearch, 'Underlying');
    }

    (async () => {
      try {
        const response = await fetch(url, { method: 'get' });

        /* istanbul ignore else */
        if (!response.ok) {
          dispatch({ type: 'ERROR', error: response.statusText });
          return;
        }

        const json: UnderlyingsResponse = await response.json();

        dispatch({
          type: 'LOADING_COMPLETE',
          instruments: json.results.instruments,
          pageNumber: json.meta.pageNumber,
          totalPages: json.meta.totalPages,
          totalResults: json.meta.totalResults,
        });
      } catch (error) /* istanbul ignore next */ {
        if (error.name === 'AbortError') {
          return;
        } else {
          dispatch({ type: 'ERROR', error: error.toString() });
        }
      }
    })();

    return () => abort();
    // eslint-disable-next-line
  }, [apiHost, xst, cst, currentAccountId, state.underlyingSearch, state.sortPreference]);

  return {
    ...state,
    updateQuery: (query: string) => {
      /* istanbul ignore else */
      if (query !== state.underlyingSearch) {
        dispatch({ type: 'SET_QUERY', query });
        saveUnderlyingTabConfig({
          underlyingSearch: query,
          sortPreference: state.sortPreference,
        });
      }
    },
    updateSorting: (sort: UnderlyingSort) => {
      dispatch({ type: 'SET_SORT', sort });
      saveUnderlyingTabConfig({
        underlyingSearch: state.underlyingSearch,
        sortPreference: sort,
      });

      /* istanbul ignore else */
      if (actions.onSort) {
        actions.onSort(
          `${sort === UnderlyingSort.popularity ? 'popularity' : 'underlyingName'}:${
            sort[0] === '+' ? 'asc' : 'desc'
          }`,
          'Underlying'
        );
      }
    },
  };
};

export default useUnderlyingMarket;

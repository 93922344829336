import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

async function initialiseI18n(lng = 'en') {
  const langShortCode = lng.slice(0, 2);
  const localeFile = await import(`./locales/${langShortCode}.json`);

  return i18n.use(initReactI18next).init({
    resources: {
      [langShortCode]: { translation: localeFile },
    },
    lng,
    fallbackLng: 'en',
  });
}

export default initialiseI18n;
import React, { CSSProperties } from 'react';
import { AutoSizer, InfiniteLoader, List } from 'react-virtualized';
import WarrantRow from './WarrantRow';
import { WARRANT_ROWS_PER_PAGE } from './WarrantTab';
import { SelectMarketCallback, Warrant } from './MtfScreener';
import { useWebsiteScreenerParams } from '../hooks/use-website-screener-params';

export type WarrantListProps = {
  data: Warrant[];
  loadNextPage: Function;
  scrollToIndex?: number;
  selectMarket: SelectMarketCallback;
  selectedMarketId: string;
  totalResults: number;
  utcOffset: number;
  priceTicksEnabled: boolean;
};

const WarrantList: React.FC<WarrantListProps> = ({
  data,
  loadNextPage,
  scrollToIndex,
  selectMarket,
  selectedMarketId,
  totalResults,
  utcOffset,
  priceTicksEnabled,
}) => {
  const { rowHeight } = useWebsiteScreenerParams();

  function isRowLoaded({ index }: { index: number }) {
    return !!data[index];
  }

  function rowRenderer({
    key,
    style,
    index,
  }: {
    key: string;
    style: CSSProperties;
    index: number;
  }) {
    const warrant: Warrant = data[index];
    return (
      warrant && (
        <WarrantRow
          key={key}
          style={style}
          warrant={warrant}
          selectMarket={selectMarket}
          selectedMarketId={selectedMarketId}
          utcOffset={utcOffset}
          priceTicksEnabled={priceTicksEnabled}
        />
      )
    );
  }

  return (
    <AutoSizer>
      {({ height, width }) => (
        <InfiniteLoader
          isRowLoaded={isRowLoaded}
          loadMoreRows={() => loadNextPage()}
          rowCount={totalResults}
          minimumBatchSize={WARRANT_ROWS_PER_PAGE}
        >
          {({ onRowsRendered, registerChild }) => (
            <List
              width={width}
              height={height}
              ref={registerChild}
              onRowsRendered={onRowsRendered}
              rowCount={data.length}
              rowHeight={rowHeight}
              rowRenderer={rowRenderer}
              scrollToIndex={scrollToIndex}
            />
          )}
        </InfiniteLoader>
      )}
    </AutoSizer>
  );
};

export default WarrantList;

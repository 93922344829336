import { getTrimmedAndEncodedSearchPhrase } from './search-utils';

export const getUnderlyingMarketURLParams = (
  pageSize: number,
  searchPhrase: string,
  sort: string
) => {
  return {
    pageSize,
    defaultForUnderlyingOnly: true,
    fields: 'referenceEpic,underlyingName,underlyingId,isin,instrumentType',
    includes: 'referenceMarketSnapshotData',
    searchTerm: getTrimmedAndEncodedSearchPhrase(searchPhrase),
    sort,
  };
};

export const getConstantURLParams = (pageSize: number, pageNumber: number) => {
  return {
    instrumentType: 'CONSTANT_LEVERAGE',
    pageSize,
    pageNumber,
    fields: 'id,name,epic,underlyingName,leverageFactor,optionType,referenceEpic,isin',
  };
};

export const getTurboURLParams = (pageSize: number, pageNumber: number) => {
  return {
    instrumentType: 'TURBO_24',
    pageSize,
    pageNumber,
    fields:
      'id,name,epic,underlyingName,underlyingLevel,leverage,strikeLevel,distance,optionType,referenceEpic,isin',
  };
};

export const getWarrantURLParams = (pageSize: number, pageNumber: number) => {
  return {
    instrumentType: 'WARRANT',
    pageSize,
    pageNumber,
    fields:
      'id,name,epic,expiry,underlyingName,underlyingLevel,strikeLevel,optionType,referenceEpic,isin',
  };
};

export const getIsinURLParams = (searchPhrase: string) => {
  return {
    fields:
      'id,name,epic,expiry,instrumentType,underlyingName,underlyingLevel,leverage,leverageFactor,strikeLevel,distance,optionType,referenceEpic,isin',
    isin: searchPhrase,
  };
};

import React, { CSSProperties, useEffect, useMemo, useState } from 'react';
import { SelectUnderlyingMarketCallback, UnderlyingMarket } from './MtfScreener';
// @ts-ignore
import gridStyles from '../styles/grid.module.css';
import PriceTick from './PriceTick';
import { presentNumber } from '../utils/rounding-utils';
import { calculateMid } from '../utils/mid-utils';
import { useSessionData } from '../hooks/use-session-data';
import { useLsSubscription } from '@ig-wpx/lightstreamer';
import { useWebsiteScreenerParams } from '../hooks/use-website-screener-params';

export type UnderlyingMarketRowProps = {
  selectUnderlyingMarket: SelectUnderlyingMarketCallback;
  selectedUnderlyingMarketId: string;
  style: CSSProperties;
  market: UnderlyingMarket;
  priceTicksEnabled: boolean;
};

const UnderlyingMarketRow: React.FC<UnderlyingMarketRowProps> = ({
  market,
  style,
  selectUnderlyingMarket,
  selectedUnderlyingMarketId,
  priceTicksEnabled,
}) => {
  const { currentAccountId } = useSessionData();
  const { allowUnauthorizedAPI } = useWebsiteScreenerParams();

  const [referencePrice, setReferencePrice] = useState<number>();
  const [changePct, setChangePct] = useState<number>();
  const [change, setChange] = useState<number>();

  const FIELDS = ['bid', 'change', 'change_pct', 'offer'];
  const SUBSCRIPTION_URL = allowUnauthorizedAPI
    ? `V2-F-BID,OFR,CPT,CPC|${market.referenceEpic}`
    : `MARKET_V4:${currentAccountId}:${market.referenceEpic}`;

  const subscriptionItems = useMemo(() => [SUBSCRIPTION_URL], [SUBSCRIPTION_URL]);

  const { data } = useLsSubscription(subscriptionItems, FIELDS, 'MERGE');

  useEffect(() => {
    if (!data) {
      return;
    }
    const parsedOfferValue = data.offer ? parseFloat(data.offer) : undefined;
    const parsedBidValue = data.bid ? parseFloat(data.bid) : undefined;
    const calculatedMidPrice = calculateMid(parsedOfferValue, parsedBidValue);

    setReferencePrice(calculatedMidPrice);

    /* istanbul ignore else */
    if (data.change_pct) {
      setChangePct(parseFloat(data.change_pct));
    }

    /* istanbul ignore else */
    if (data.change) {
      setChange(parseFloat(data.change));
    }
  }, [data]);

  const showCellData = (cellData: undefined | string | number) => {
    return cellData ? cellData.toString() : '-';
  };

  const showCellDataWithTicks = (cellData: undefined | number) => {
    return cellData === undefined || isNaN(cellData) ? (
      <span className="price-tick">-</span>
    ) : (
      <PriceTick
        price={+presentNumber(cellData)}
        className="ig-signal-detail_level-value ig-signal-detail_level-value--latest-price"
      />
    );
  };

  const cellClasses = (value: number | undefined) => {
    if (isNaN(value)) {
      return '';
    }

    if (value && value > 0) {
      return gridStyles.gridValuePositive;
    }

    if (value && value < 0) {
      return gridStyles.gridValueNegative;
    }

    return '';
  };

  const loadedRow = (
    <li
      className={`${gridStyles.gridRow} ${
        market.underlyingId === selectedUnderlyingMarketId ? gridStyles.gridRowSelected : ''
      }`}
      style={style}
      data-testid="underlying-list-row"
      onClick={() => selectUnderlyingMarket(market)}
    >
      <div
        className={`${gridStyles.gridRowItem} ${gridStyles.gridUnderlyingRowMarket}`}
        title={showCellData(market.underlyingName)}
        data-testid="underlying-market-name"
      >
        {showCellData(market.underlyingName)}
      </div>
      <div
        className={`${gridStyles.gridRowItem} ${gridStyles.gridRowReferencePrice}`}
        title={showCellData(referencePrice)}
        data-testid="reference-price"
      >
        {priceTicksEnabled ? showCellDataWithTicks(referencePrice) : showCellData(referencePrice)}
      </div>
      <div
        className={`${gridStyles.gridRowItem} ${gridStyles.gridRowChange} ${cellClasses(change)}`}
        title={showCellData(change)}
        data-testid="change-price"
      >
        {showCellData(change)}
      </div>
      <div
        className={`${gridStyles.gridRowItem} ${gridStyles.gridRowPercentChange} ${cellClasses(
          changePct
        )}`}
        title={showCellData(changePct)}
        data-testid="change-percentage"
      >
        {showCellData(changePct)}
      </div>
    </li>
  );

  return loadedRow;
};

export default UnderlyingMarketRow;

import React from 'react';
// @ts-ignore
import styles from '../styles/tabs.module.css';
import { useTranslation } from 'react-i18next';
import ArrowBadge from './ArrowBadge';
import { MarkMTFAdvancedSearchAsSeenCallback } from './MtfScreener';
import { useWebsiteScreenerParams } from '../hooks/use-website-screener-params';

export type SetActiveTabCallback = (activeTab: SearchTab) => void;

export type TabsProps = {
  activeTab: SearchTab;
  markMTFAdvancedSearchAsSeen: MarkMTFAdvancedSearchAsSeenCallback;
  setActiveTab: SetActiveTabCallback;
  showMTFAdvancedSearchAsNewFeature: boolean;
};

export enum SearchTab {
  UNDERLYING = 'Underlying',
  ISIN = 'ISIN',
  TURBO = 'Turbo',
  WARRANT = 'Warrant',
  CONSTANT = 'Constant',
  ADVANCED_SEARCH = 'AdvancedSearch',
}

const Tabs: React.FC<TabsProps> = ({
  activeTab,
  markMTFAdvancedSearchAsSeen,
  setActiveTab,
  showMTFAdvancedSearchAsNewFeature,
}) => {
  const { t } = useTranslation();
  const { allowUnauthorizedAPI } = useWebsiteScreenerParams();

  const handleTabChange = (selectedTab: SearchTab) => {
    setActiveTab(selectedTab);

    if (selectedTab === SearchTab.ADVANCED_SEARCH) {
      markMTFAdvancedSearchAsSeen();
    }
  };

  const underlyingTabNav = (
    <li
      className={`${styles.tabsNavTab} ${
        activeTab === SearchTab.UNDERLYING ? styles.tabsNavTabActive : ''
      }`}
      data-testid="underlying-tab"
      onClick={() => handleTabChange(SearchTab.UNDERLYING)}
      key="underlying-tab-nav"
    >
      <span className={styles.tabsNavText}>{t('navigation.underlying')}</span>
    </li>
  );

  const isinTabNav = (
    <li
      className={`${styles.tabsNavTab} ${
        activeTab === SearchTab.ISIN ? styles.tabsNavTabActive : ''
      }`}
      data-testid="isin-tab"
      onClick={() => handleTabChange(SearchTab.ISIN)}
      key="isin-tab-nav"
    >
      <span className={styles.tabsNavText}>{t('navigation.isin')}</span>
    </li>
  );

  const advancedSearchTabNav = (
    <li
      className={`${styles.tabsNavTab} ${
        activeTab === SearchTab.ADVANCED_SEARCH ? styles.tabsNavTabActive : ''
      }`}
      data-testid="advanced-search-tab"
      onClick={() => handleTabChange(SearchTab.ADVANCED_SEARCH)}
      key="advanced-search-tab-nav"
    >
      <span className={styles.tabsNavText}>
        {t('navigation.advanced-search')}
        {showMTFAdvancedSearchAsNewFeature && (
          <ArrowBadge label={t('turbo-screener-info.badge-new')} />
        )}
      </span>
    </li>
  );

  const visibleTabs = allowUnauthorizedAPI
    ? [advancedSearchTabNav, isinTabNav]
    : [underlyingTabNav, isinTabNav, advancedSearchTabNav];

  return (
    <div className={styles.tabsNavContainer}>
      <ul className={`${styles.tabsNav} ${styles.tabsNavLeft}`}>
        {visibleTabs.map((tab) => {
          return tab;
        })}
      </ul>
    </div>
  );
};

export default Tabs;

import React, { CSSProperties, useEffect, useMemo, useState } from 'react';
// @ts-ignore
import gridStyles from '../styles/grid.module.css';
import { InstrumentType, SelectMarketCallback, Turbo } from './MtfScreener';
import { useTranslation } from 'react-i18next';
import { presentNumber } from '../utils/rounding-utils';
import { useLsSubscription } from '@ig-wpx/lightstreamer';
import PriceTick from './PriceTick';
import { useSessionData } from '../hooks/use-session-data';
import { useWebsiteScreenerParams } from '../hooks/use-website-screener-params';

export type TurboRowProps = {
  selectMarket: SelectMarketCallback;
  selectedMarketId: string;
  style: CSSProperties;
  turbo: Turbo;
  priceTicksEnabled: boolean;
};

const TurboRow: React.FC<TurboRowProps> = ({
  turbo,
  style,
  selectMarket,
  selectedMarketId,
  priceTicksEnabled,
}) => {
  const { t } = useTranslation();
  const { currentAccountId } = useSessionData();
  const { allowUnauthorizedAPI } = useWebsiteScreenerParams();

  const [bid, setBid] = useState<number>();
  const [offer, setOffer] = useState<number>();
  const [leverage, setLeverage] = useState<number>(turbo.leverage);
  const [marketAvailability, setMarketAvailability] = useState<boolean>(true);

  const NO_VALUE = '-';
  const FIELDS = ['bid', 'offer', 'leverage', 'market_status'];
  const SUBSCRIPTION_URL = allowUnauthorizedAPI
    ? `V2-F-BID,OFR,LEVERAGE|${turbo.epic}`
    : `MARKET_V4:${currentAccountId}:${turbo.epic}`;

  const subscriptionItems = useMemo(() => [SUBSCRIPTION_URL], [SUBSCRIPTION_URL]);

  const { data } = useLsSubscription(subscriptionItems, FIELDS, 'MERGE');

  useEffect(() => {
    if (!data) {
      return;
    }

    /* istanbul ignore else */
    if (data.bid) {
      setBid(parseFloat(data.bid));
    }

    /* istanbul ignore else */
    if (data.leverage) {
      setLeverage(parseInt(data.leverage, 10));
    }

    /* istanbul ignore else */
    if (data.offer) {
      setOffer(parseFloat(data.offer));
    }

    const isMarketAvailable = data.market_status !== 'UNAVAILABLE';

    setMarketAvailability(isMarketAvailable);
  }, [data]);

  const showCellData = (cellData: undefined | string | number) => {
    return cellData ? cellData.toString() : NO_VALUE;
  };

  const showCellNumberData = (cellData: undefined | number) => {
    return cellData === undefined || isNaN(cellData) ? NO_VALUE : presentNumber(cellData);
  };

  const showCellNumberDataWithTicks = (cellData: undefined | number) => {
    return cellData === undefined || isNaN(cellData) ? (
      <span className={gridStyles.noPriceTickPlaceholder}>{NO_VALUE}</span>
    ) : (
      <PriceTick price={+presentNumber(cellData)} />
    );
  };

  const showAsPercentage = (cellData: undefined | number) => {
    return cellData === undefined || isNaN(cellData)
      ? NO_VALUE
      : `${(Math.round(cellData * 100) / 100).toFixed(2)}%`;
  };

  const showDirection = (optionType: undefined | string) => {
    return optionType ? t(`direction.${optionType.toLowerCase()}`) : NO_VALUE;
  };

  const loadedRow = (
    <li
      className={`${gridStyles.gridRow} ${
        turbo.id === selectedMarketId ? gridStyles.gridRowSelected : ''
      } ${!marketAvailability ? gridStyles.gridRowDisabled : ''}`}
      data-testid="turbo-list-row"
      style={style}
      onClick={() => {
        /* istanbul ignore else */
        if (marketAvailability) {
          selectMarket({ ...turbo, instrumentType: InstrumentType.TURBO });
        }
      }}
    >
      <div
        className={`${gridStyles.gridRowItem} ${gridStyles.gridTurboRowMarket}`}
        title={showCellData(turbo.underlyingName)}
        data-testid="underlying-name"
      >
        {showCellData(turbo.underlyingName)}
      </div>
      <div
        className={`${gridStyles.gridRowItem} ${gridStyles.gridRowDirection}`}
        title={showDirection(turbo.optionType)}
        data-testid="option-type"
      >
        {showDirection(turbo.optionType)}
      </div>
      <div
        className={`${gridStyles.gridRowItem} ${gridStyles.gridRowKOLevel}`}
        title={showCellNumberData(turbo.strikeLevel)}
      >
        {showCellNumberData(turbo.strikeLevel)}
      </div>
      <div
        className={`${gridStyles.gridRowItem} ${gridStyles.gridRowUnderlyingLevel}`}
        title={showCellNumberData(turbo.underlyingLevel)}
      >
        {showCellNumberData(turbo.underlyingLevel)}
      </div>
      <div
        className={`${gridStyles.gridRowItem} ${gridStyles.gridRowDistanceToKO}`}
        title={showAsPercentage(turbo.distance)}
      >
        {showAsPercentage(turbo.distance)}
      </div>
      <div
        className={`${gridStyles.gridRowItem} ${gridStyles.gridRowLeverage}`}
        title={showCellData(leverage)}
        data-testid="leverage"
      >
        {showCellData(leverage)}
      </div>
      <div
        className={`${gridStyles.gridRowItem} ${gridStyles.gridRowSell}`}
        title={showCellNumberData(bid)}
        data-testid="bid"
      >
        {priceTicksEnabled ? showCellNumberDataWithTicks(bid) : showCellNumberData(bid)}
      </div>
      <div
        className={`${gridStyles.gridRowItem} ${gridStyles.gridRowBuy}`}
        title={showCellNumberData(offer)}
        data-testid="offer"
      >
        {priceTicksEnabled ? showCellNumberDataWithTicks(offer) : showCellNumberData(offer)}
      </div>
    </li>
  );

  const loadingRow = (
    <li
      className={`${gridStyles.gridRow} ${gridStyles.gridRowLoading}`}
      data-testid="row-loading"
      style={style}
    >
      <div className={gridStyles.gridRowLoadingAnimation}></div>
    </li>
  );

  return turbo.isLoading ? loadingRow : loadedRow;
};

export default TurboRow;
